<template>
  <v-app>
    <v-content>
        <template v-if="isLoaded">
          <Breadcrumbs 
            class="hide-mobile"
          />
          <div class="hide-desktop">
            <div 
            class="mobile-navigation"
            >
              <v-row class="d-flex justify-center">
                <v-col class="align-left">
                  <v-btn 
                  dark
                  fab
                  text
                  class="ml-4"
                  @click="isMobileMenuActive = !isMobileMenuActive"
                  >
                    <v-icon v-if="!isMobileMenuActive">
                      menu
                    </v-icon>
                    <v-icon v-else>
                      close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <div 
          class="mobile-panel"
          :class="{'active-mobile-panel' : isMobileMenuActive}"
          >
            <Navigation 
                :user="user"
                :tenant="tenant"
              />
          </div>
          <div class="navigation-container">
            <Navigation 
              :user="user"
              :tenant="tenant"
            />
          </div>
          <div class="views">
            <router-view/>
          </div>
        </template>
        <v-row v-else class="align-center justify-center">
        <v-overlay 
        :value="!isLoaded"
        color="primary"
        >
          <v-progress-circular rotate="220" width="3" indeterminate size="150" color="#fff"></v-progress-circular>
        </v-overlay>
        </v-row>
    </v-content>
  </v-app>
</template>

<script>
import storage from '@/services/storage';
import { mapGetters } from 'vuex';
import { store } from './store'
import Navigation from '@/components/globals/Navigation';
import Breadcrumbs from '@/components/globals/Breadcrumbs';
import isEmpty from 'lodash.isempty';
import * as firebase from "firebase/app";
import "firebase/auth";

export default {
  name: 'app',
  data () {
    return {
      isMobileMenuActive: false
    }
  },
  watch: {
    user(val) {
      if (!isEmpty(val)) {
        this.$store.dispatch('FETCH_TENANT', this.$route.query.accessToken);
      }
    }
  },
    async created () {
        let accessToken = this.$route.query.accessToken;

        if (accessToken) {
            storage.local.set('accessToken', this.$route.query.accessToken);
            this.$router.push('/');
        } else {
            accessToken = storage.local.get('accessToken');
        }

       await this.$store.dispatch('FETCH_USER', accessToken);

    },

  components: {
    Navigation,
    Breadcrumbs
  },
  computed: {
    ...mapGetters([
      'user',
      'tenant'
    ]),
    isLoaded() {
      return !isEmpty(this.tenant) && !isEmpty(this.user);
    }
  }
}
</script>

<style lang="scss">
$borderRadius: 10px;
$darkColor: #373542;
$break-small: 600px;

.no-touch-events {
  pointer-events: none;
}
.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: linear-gradient(left, rgb(241, 243, 246, 0), rgb(241, 243, 246, 0.4));;
}
.hide-mobile {
  display: block;
    @media screen and (max-width: $break-small) {
      display: none;
    }
}

.hide-desktop {
  display: none;
    @media screen and (max-width: $break-small) {
      display: block;
    }
}

.align-right{
  text-align: right;
}

.align-left{
  text-align: left;
}
.mobile-panel {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -101%;
  z-index: 9;
  border-radius: 0 !important;
  transition: all .25s cubic-bezier(0.50, 0, 0.75, 0.7);
}
.active-mobile-panel {
  left: 0;
}
.mobile-navigation {
  width: 100%;
  height: 70px;
  background-color: #171B3A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-card {
    padding: 2em;
    max-width: 900px;
    margin: 2em auto 0 auto;
    border: 1px solid #ddd !important;
    border-radius: 15px !important;
}

.navigation-container {
  width:  250px;
  height: 100%;
  position: fixed;
  top: 0;
  @media screen and (max-width: $break-small) {
    position: absolute;
    left: -100%;
    top: 0;
    width: 250px;
    height: 100%;
  }
}

.panel {
  width: 150px;
  height: 100%;
  background-color: #2a2833;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &__scroll {
    width: 100%;
    background-color: #383644;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 10px;
    max-height: 200px;
    overflow-y: scroll;
  }

  .list {
    margin: 10px auto;
    width: 80%;
    text-align: left;
    font-size: 12px;
    cursor: pointer;
  }
  @media screen and (max-width: $break-small) {
    display: none;
  }
}
.status {
  background-color: #eee;
  color: #333;
  width: auto;
  border-radius: 50px;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #ccc;
}

.views {
  width: calc(100% - 250px);
  top: 73px;
  right: 0;
  bottom: 0;
  left: 250px;
  position: absolute;
  overflow-y: scroll;
  height: calc(100% - 75px);
  z-index: 0;
  
  @media screen and (max-width: $break-small) {
    width: 90%;
    left: 5%;
    position: relative;
    overflow-y: unset;
  }
}

#app {
  background-color: #f1f3f6;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    text-decoration: unset;
  }
  @font-face {
	font-family: 'Avalon Bold';
	src: url('../static/fonts/Avalon-Bold.otf'); 
	src: 
		local('Avalon Bold'),
		local('Avalon'),
		url('../static/fonts/Avalon-Bold.otf') 
		format('opentype');
}


  @font-face {
	font-family: 'ProximaNova-Bold';
	src: url('/static/fonts/ProximaNova-Bold.otf'); 
	src: 
		local('ProximaNova-Bold'),
		local('Proxima Nova'),
		url('/static/fonts/ProximaNova-Bold.otf') 
		format('opentype');
}

  @font-face {
	font-family: 'ProximaNova-Light';
	src: url('/static/fonts/ProximaNova-Light.otf'); 
	src: 
		local('ProximaNova-Light'),
		local('Proxima Nova'),
		url('/static/fonts/ProximaNova-Light.otf') 
		format('opentype');
}

  @font-face {
	font-family: 'ProximaNova-Thin';
	src: url('/static/fonts/ProximaNova-Thin.otf'); 
	src: 
		local('ProximaNova-Thin'),
		local('Proxima Nova'),
		url('/static/fonts/ProximaNova-Thin.otf') 
		format('opentype');
}

  .logo {
    display: none;
    transition: 3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    @media screen and (max-width: $break-small) {
      margin: 0 auto 40px auto;
      width: 80%;
      display: block;
    }
  }
  .logo-icon {
    width: 80%;
    margin: -40px 0 40px -140px;
    
  }
}

  .st0{fill:#3EC196;}
  .st1{fill:#373542; transition: 4s all cubic-bezier(0.075, 0.82, 0.165, 1);}
  .st1-hide {
    fill: none;
  }

.site-notification {
  background-color: #3EC196;
  width: 100%;
  position: fixed;
  top: 0;
  height: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  left: 0;
  font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;
  overflow: hidden;
  transition: all .5s cubic-bezier(0.86, 0, 0.07, 1);
  font-size: 14px;
  z-index: 99;
  @media screen and (max-width: $break-small) {
    font-size: 4vw;
  }
}
.site-notification-active {
  height: 3vw;
  @media screen and (max-width: $break-small) {
    height: 8vw;
  }
}

.trigger {
  cursor: pointer;
  background-color: #fae9e8;
  color: #e8908a;
  border-radius: 50px;
  font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  font-size: 10px;
  padding: 1vw 35px 1vw 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 15px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7.5px;
  }
  @media screen and (max-width: $break-small) {
    padding: 1vw 35px 1vw 4vw;
  }
}
.trigger-color {
  cursor: pointer;
  background-color: #383644;
  color: #7b6996;
  border-radius: 50px;
  font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  font-size: 8px;
  padding: 1vw 35px 1vw 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 70px;
  position: absolute;
  bottom: 50px; 
  left: 50%;
  margin-left: -60px;
  
  img {
    width: 15px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7.5px;
  }
  @media screen and (max-width: $break-small) {
    padding: 1vw 35px 1vw 4vw;
  }
}
.tools-low {
  background-color: #e6ffee;
  color: #3ec196;
}
.tools-medium {
  background-color: #cac3d5;
  color: #7b6996;
}
.tools-high {
  background-color: #fae9e8;
  color: #e8908a;
}
.tools {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  padding: 5px 10px;
  background-color: #fff;
  left: -10px;
  top: 100%;
  z-index: 99;
  border-radius: $borderRadius;
  font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 24px 0px;
  transform: scale(0.8);
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
    @media screen and (max-width: $break-small) {
      padding: 4vw 6vw;
    }

}

  .tool {
    text-transform: uppercase;
    font-size: 8px;
    margin: 10px;
    border-radius: 50px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: unset;
    max-width: 50px;

  @media screen and (max-width: $break-small) {
    font-size: 10px;
    margin: 4vw 0;
    border-radius: 50px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    padding: 2vw 4vw;
  }
  }

.tools-active {
  pointer-events: unset;
  opacity: 1;
  transform: scale(1);

}
.spacer {
    margin: 0 5vw 0 5px;
    @media screen and (max-width: $break-small) {
      margin: 0 5vw 0 1vw;
    }
}

.options {
  width: 90%;
  height: auto;
  padding: 2vw 0;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;

  &__action {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 20px;
    right: 5%;

    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    border: .09rem dashed #b5e3c8;

    display: flex;
    align-items: center;
    justify-content: center;

    img { 
      width: 75%;
    }
  }
  span {
    color: #3EC196;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
  }
  &__icon {
    width: 15px;
    height: 15px;
    position: relative;

    border-radius: 50%;
    background-color: #fff;
    border: .09rem dashed #b5e3c8;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5vw 0 1vw;

    img { 
      width: 75%;
    }
  }
  @media screen and (max-width: $break-small) {
    width: 90%;
    height: auto;
    padding: 6vw 0;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;

    &__action {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 20px;
      right: 5%;

      border-radius: 50%;
      position: absolute;
      background-color: #fff;
      border: .09rem dashed #b5e3c8;

      display: flex;
      align-items: center;
      justify-content: center;

      img { 
        width: 75%;
      }
    }
    span {
      color: #3EC196;
      font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    }
    &__icon {
      width: 25px;
      height: 25px;
      position: relative;

      border-radius: 50%;
      background-color: #fff;
      border: .09rem dashed #b5e3c8;

      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5vw 0 2vw;

      img { 
        width: 75%;
      }
    }

  }

}

.controls {
  width: 90%;
  display: flex;
  margin-bottom: 15px;
  position: relative;

  &__date {
    font-size: 10px;
    font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;
  }

  &__title {
    width: 90%;
    font-weight: bolder;
    font-size: 20px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    color: $darkColor;
    text-align: left;
    &::placeholder {
      color: $darkColor;
    }
  }
  &__sub-title {
    width: 90%;
    font-weight: bolder;
    font-size: 16px;
    font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
    color: $darkColor;
    &::placeholder {
      color: $darkColor;
    }
  }
  &__action {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content:baseline;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  &__tasks {
    width: 100%;
  }
  &__task-list {
    width: 100%;
    padding: 0 1vw 6vw 25px;
    position: relative;
    margin-left: 15px;
  }
  &__task-border {
    position: absolute;
    top: 0;
    height: 100%;
    left: -1px;
    background-color: #fff;
    border-left: .09rem solid #b5e3c8;
  } 
  &__task-checked {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -14px;
    background-color: #fff;
    border: .09rem dashed #b5e3c8;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
      height: 50%;
    }
  }
  &__task-checked-active {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -14px;
    background-color: #fff;
    background-color: #b5e3c8;
    border: .09rem solid #b5e3c8;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80%;
      height: 80%;
    }
  } 
  .completed {
    color: #3ec196;
    font-style: italic;
  }
  &__more {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
  } 

  textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    padding-left: 0;
    font-size: 14px;
    background-color: transparent;
    font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;

  }

  @media screen and (max-width: $break-small) {

    width: 90%;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    
    &__title {
      width: 90%;
      font-weight: bolder;
      font-size: 6vw;
      font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
      color: $darkColor;
      text-align: left;
      &::placeholder {
        color: $darkColor;
      }
    }
    &__sub-title {
      width: 90%;
      font-weight: bolder;
      font-size: 4.5vw;
      font-family: 'ProximaNova-Bold', 'Avenir', sans-serif;
      color: $darkColor;
      &::placeholder {
        color: $darkColor;
      }
    }
    &__action {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content:baseline;
      position: relative;
      img {
        width: 100%;
      }
    }

    &__tasks {
      width: 100%;
    }
    &__task-list {
      width: calc(69vw - 70px);
      padding: 0 1vw 6vw 25px;
      // background-color: red;
      position: relative;
      // border-left: 1px solid #b5e3c8;
      margin-left: 15px;
    }
    &__task-border {
      position: absolute;
      top: 0;
      height: 100%;
      left: -1px;
      background-color: #fff;
      border-left: .09rem solid #b5e3c8;
    } 
    &__task-checked {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -14px;
      background-color: #fff;
      border: .09rem dashed #b5e3c8;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
        height: 50%;
      }
    }
    &__task-checked-active {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -14px;
      background-color: #fff;
      background-color: #b5e3c8;
      border: .09rem solid #b5e3c8;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        height: 80%;
      }
    } 
    .completed {
      color: #3ec196;
      font-style: italic;
    }
    &__more{
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;

    } 

    textarea {
      width: 100%;
      border: none;
      outline: none;
      padding: 0;
      padding-left: 0;
      font-size: 4.5vw;
      background-color: transparent;
      font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;

    }
  }
}
.container {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}
.task-input {
  font-size: 18px;
  font-family: 'ProximaNova-Thin', 'Avenir', sans-serif;

}
.home-input {
  width: 300px;
  background-color: #e6ffee;
  border-radius: $borderRadius;
  border-top-left-radius: 0;
  text-align: center;
  font-size: 15px;
  margin: 15px auto;
  padding: 1vw 2vw; 

  @media screen and (max-width: $break-small) {
    font-size: 5vw;
    margin: 30px auto;
    padding: 4vw 2vw; 
  }
  
}

</style>
