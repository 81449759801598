<template>
    <div class="room">
        <v-snackbar
        v-model="showMessage"
        :top="true"
        :timeout="1000"
        >
        saved 🎉

        </v-snackbar>
        <toolbar>
            <v-row class="d-flex align-center justify-end">
                <v-spacer></v-spacer>
                <v-row class="d-flex align-end justify-end">
                    <v-btn 
                    @click="downloadPDF()" 
                    color="secondary"
                    depressed
                    text
                    :loading="isDownloading"
                    >
                        get labels
                            <v-icon class="ml-4">
                                print
                            </v-icon>
                    </v-btn>

                    <a class="mr-8" :href="`${urls.room}/${room.urlPath}`" target="_blank" rel="noopener noreferrer">
                        <v-btn 
                        color="secondary"
                        depressed
                        text
                        >
                            view room
                            <v-icon class="ml-4">
                                open_in_new
                            </v-icon>
                        </v-btn>
                    </a>
                </v-row>
                    <v-btn
                        depressed
                        color="secondary" 
                        @click="save"
                        >
                        Save
                    </v-btn>
            </v-row>
        </toolbar>
        <v-dialog width="500px" v-model="chooseMore">
            <v-card class="pa-5 mx-auto dialog__card">
                <v-row v-for="(item, index) in catalog" :key="index">
                    <v-col cols="2">
                        <!-- TODO replace with thumbnail-->
                        <v-img v-if="item.images[0] && item.images[0]" :src="item.images[0].url" height="50px" width="50px">

                        </v-img>
                    </v-col>
                    <v-col cols="5" v-if="item.name">
                        {{item.name}}
                    </v-col>
                    <v-col cols="5" v-else>
                        Draft
                    </v-col>
                    <v-col v-if="room.lineItems" class="d-flex justify-end">
                            <v-btn 
                            depressed 
                            @click="addToRoom(item)"
                            color="secondary"
                            text
                            v-if="!room.lineItems.includes(item.id)"
                            >
                            add
                            <v-icon class="ml-2" small>
                                add_circle_outline
                            </v-icon>
                            </v-btn>
                            <v-btn 
                            depressed 
                            @click="removeFromRoom(item, index)"
                            color="error"
                            text
                            v-else
                            >
                            remove
                            <v-icon class="ml-2" small>
                                close
                            </v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card flat class="content-card hide-mobile">
                    <v-row>
                        <div class="overline mb-4">General</div>
                    </v-row>
                    <v-row class="mb-8">
                        <v-col>
                            <v-switch
                            v-model="room.isPublished"
                            color="secondary"
                            class="mr-6"
                            flat
                            inset
                            hide-details="true"
                            label="Published"
                            ></v-switch> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="6">
                            <v-text-field
                                v-model="room.name"
                                label="Name"
                                required
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-text-field
                                readonly
                                v-model="newPath"
                                label="URL"
                                required
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <AddNewImage
                            :thumbnail="room.siteLogo.url"
                            :isUploading="isUploading"
                            v-on:upload-file="uploadLogo"
                            />
                        </v-col>
                        <v-col>
                            <v-textarea height="180px" auto-grow label="Description" v-model="room.description" outlined color="secondary">
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card flat class="content-card hide-mobile">
                    <v-row>
                        <div class="overline mb-4">Settings</div>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch
                            v-model="room.forceConvert"
                            color="secondary"
                            class="mb-2"
                            flat
                            inset
                            hide-details="true"
                            label="Require Contact Information"
                            ></v-switch>

                            <v-switch
                            v-model="room.showQRCode"
                            color="secondary"
                            class="mb-2"
                            flat
                            inset
                            hide-details="true"
                            label="Display QR Code"
                            ></v-switch>
                            <v-switch
                            v-model="room.showPrice"
                            color="secondary"
                            class="mb-2"
                            flat
                            inset
                            hide-details="true"
                            label="Show Price"
                            ></v-switch> 
                        </v-col>
                        <v-col>
                            <v-switch
                            v-model="room.showColor"
                            color="secondary"
                            class="mb-2"
                            flat
                            inset
                            hide-details="true"
                            label="Show Color Swatches"
                            ></v-switch> 

                            <v-switch
                            v-model="room.showAugmentedReality"
                            color="secondary"
                            class="mb-2"
                            flat
                            inset
                            hide-details="true"
                            label="Show Augmented Reality"
                            ></v-switch> 
                        </v-col>
                    </v-row>
                </v-card>
                <v-card flat class="content-card hide-mobile">
                    <v-row>
                        <div class="overline mb-4">Analytics</div>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col cols="2" md="1">
                                    <v-switch
                                    v-model="analytics.google.isActive"
                                    color="secondary"
                                    flat
                                    inset
                                    hide-details="true"
                                    ></v-switch> 
                                </v-col>
                                <v-text-field
                                v-model="analytics.google.trackingId"
                                label="Google Tracking Code"
                                color="secondary"
                                outlined
                                :disabled="!analytics.google.isActive"
                                >
                                </v-text-field>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card flat class="content-card hide-mobile">
                    <v-row>
                        <div class="overline mb-8">Artwork</div>
                        <v-spacer></v-spacer>
                        <v-col class="align-right">
                            <v-btn 
                            depressed 
                            @click="chooseMore = true"
                            color="secondary"
                            >
                            Manage
                            <v-icon class="ml-2" small>
                                attachment
                            </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <draggable 
                        v-bind="dragOptions" 
                        v-model="works" 
                        draggable=".room__artwork" 
                        handle=".room__artwork-options"
                        @change="reorderImages"
                        >
                                <div 
                                class="room__artwork"
                                :key="`${item.id}`"
                                v-for="(item) in works"
                                >
                                    <v-btn  
                                    depressed 
                                    small 
                                    fab
                                    color="#fff" 
                                    class="room__artwork-options"
                                    >
                                        <v-icon color="secondary">
                                            drag_indicator
                                        </v-icon>
                                    </v-btn>
                                    <v-icon 
                                    class="room__artwork-expand" 
                                    color="#fff"
                                    large
                                    >
                                        aspect_ratio
                                    </v-icon>
                                    <div v-if="item.images && item.images[0]" class="room__artwork-image" :style="{'backgroundImage': `url(${item.images[0].url})`}">
                                    </div>
                                    <div class="room__artwork-name">
                                        {{item.name}}
                                    </div>
                                </div>
                        </draggable>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import roomService from '@/services/roomService';
import catalogService from '@/services/catalog';
import { urls, routes } from '@/utils/constants';
import newRoom from '@/utils/room';
import { catalog } from '../store/modules/catalog';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import Toolbar from '@/components/globals/Toolbar';
import clonedeep from 'lodash.clonedeep';
import AddNewImage from '@/components/images/AddNewImage';
const axios = require('axios').default;

    export default {
        data() {
            return {
                urls,
                analytics: {
                    google: {
                        isActive:  false,
                        trackingId: ''
                    }
                },
                showMessage: false,
                isLoading: false,
                isDownloading: false,
                isUploading: false,
                chooseMore: false,
                createMode: false,
                room: {},
                works: []
            }
        },
        components: {
            draggable,
            Toolbar,
            AddNewImage
        },
        async created () {

            let id = this.$route.params.id;

            if (id === 'new') {
                this.room = clonedeep(newRoom.schema());
                this.room.tenantId = this.tenant.id;
                this.createMode = true;
            } else {
                
                this.room = await roomService.get(id);
                let params = {
                    id: this.room.id,
                    lineItems: this.room.lineItems
                }
                this.works = await catalogService.getRoomCatalog(params);
                this.$store.dispatch('LOAD_CATALOG');
                if (this.room && this.room.analytics && this.room.analytics.google) {
                    this.analytics = this.room.analytics;
                }
            }
                const breadcrumbs = {
                    items: [
                        {
                            text: 'Marketing',
                            disabled: false,
                            exact: true,
                            to: {
                                name: routes.marketing
                            }
                        },
                        {
                            text: 'Rooms',
                            disabled: false,
                            exact: true,
                            to: {
                                name: routes.rooms
                            }
                        },
                        {
                            text: this.room.name ? this.room.name : 'New',
                            disabled: true
                        }
                    ]
                };
                this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
        },
        methods: {
            async downloadPDF(){
                this.isDownloading = true;
                try {
                    // const response = await axios.get(`http://localhost:5001/roxyart-io/us-central1/auth-api/pdf?url=${urls.room}?getResource=true&room=${this.room.urlPath}&fileName=${this.room.urlPath}`);
                    const response = await axios.get(`https://us-central1-roxyart-io.cloudfunctions.net/auth-api/pdf?url=${urls.room}?getResource=true&room=${this.room.urlPath}&fileName=${this.room.urlPath}`)

                    if (response && response.data) {
                        window.open(response.data, '_blank');
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isDownloading = false;
                }
            },
            async addToRoom(item) {
                let params;
                try {
                    let items = this.room.lineItems;
                    items.push(item.id);
                    params = {
                        id: this.room.id,
                        lineItems: items
                    }
                    this.works = await catalogService.getRoomCatalog(params);
                    await roomService.update(this.room);
                } catch (error) {
                    console.log(error);
                }
            },
            async removeFromRoom(item, index) {
                let params;
                try {
                    let items = this.room.lineItems;
                    let itemIndex = items.findIndex((element) => element === item.id);
                    items.splice(itemIndex, 1);

                    params = {
                        id: this.room.id,
                        lineItems: items
                    }
                    this.works = await catalogService.getRoomCatalog(params);
                    await roomService.update(this.room);
                } catch (error) {
                    console.log(error);
                }
            },
            async uploadLogo(file) {
                try {
                    this.isUploading = true;
                    let params = {
                        files: [file], 
                        tenantId: this.tenant.id,
                        roomId: this.room.id
                    }
                    
                    let response = await roomService.uploadLogo(params);
                    this.room.siteLogo.url = response; 
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isUploading = false;
                }

                this.$forceUpdate()

            },
            async reorderImages() {
                let lineItems = [];
                this.works.forEach((item) => {
                    lineItems.push(item.id);
                });
                this.room.lineItems = lineItems;
                await roomService.update(this.room);
            },
            async save() {
                try {
                    if (!this.room.name.length) return  
                    this.room.analytics = this.analytics;
                    this.room.urlPath = this.newPath;
                    if (this.createMode) {
                        await roomService.create(this.room);
                        
                    } else {
                        await roomService.update(this.room);
                    }
                    this.showMessage = true;
                } catch (error) {
                    console.log(error);   
                }

            }

        },
        computed: {
            newPath () {
                if (this.room && this.room.name) {
                    let url = this.room.name;

                    url = url.replace(/^\s+|\s+$/g, "") // trim
                    url = url.toLowerCase()
                    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;"
                    var to = "aaaaaaeeeeiiiioooouuuunc------"
                    for (var i = 0, l = from.length; i < l; i++) {
                    url = url.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
                    }
                    url = url
                    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
                    .replace(/\s+/g, "-") // collapse whitespace and replace by -
                    .replace(/-+/g, "-") // collapse dashes
                    return url
                } else {
                    return ''
                }

            },
            dragOptions () {
                return {
                    animation: 300,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost'
                };
            },
            ...mapGetters([
            'catalog',
            'tenant'
            ])
        }
    }
</script>

<style lang="scss" scoped>
.room {
    &__artwork {
        width: 140px;
        height: auto;
        min-height: 140px;
        position: relative;
        float: left;
        margin: 0 10px 10px 0;
        border-radius: 10px;
        overflow: hidden;
    }
    &__artwork-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-color: #F5F6F8;
    }
    &__artwork-options {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
    }
    &__name {
        height: 40px;
        position: relative;
    }
}
.dialog {
    &__card {
        max-height: 500px;
        overflow: auto;
    }
}
</style>