<template>
    <div class="settings">
            <v-row>
                <v-col>
                     <Account />

                     <Users />
                </v-col>
            </v-row>

    </div>
</template>

<script>
import { store } from '../store'
import { mapGetters } from 'vuex';
import { routes } from '@/utils/constants';
import Account from '@/components/Account';
import Users from '@/components/users/Users';

    export default {
        data() {
            return {
                routes,
                artwork: {
                    creator: '',
                    title: '',
                    year: '',
                    medium: '',
                    location: '',
                    status: '',
                    images: {},
                    dimensions: {
                        width: '',
                        height: '',
                        depth: ''
                    },
                    price: '',
                    weight: '',
                    forSale: ''
                }
            }
        },
        components: {
            Account,
            Users
        },
        created() {
            const toolbarActions = {
                items: [
                    {
                        text: 'save',
                        color: 'primary',
                        dark: true,
                        to: {
                            name: routes.artwork
                        }
                    }
                ]
            };
            this.$store.dispatch('SET_TOOLBAR_ACTIONS', toolbarActions);

            const breadcrumbs = {
                items: [
                    {
                        text: 'Settings',
                        disabled: false,
                        exact: true
                    }
                ]
            };
            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
        },
        computed: {
            ...mapGetters([
            'user',
            'roles'
            ])
        },
        methods: {
            save() {
                store.dispatch('CREATE_ARTWORK', this.artwork)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .settings {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 100px;

        &__tabs {
            width: 96%;
            margin: 0 auto;
            background-color: transparent !important;
        }
        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__card {
            margin: 1em auto;
            width: 100%;
            max-width: 900px;
            padding: 2em;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 3px 0px;
        }

    }
</style>
<style lang="scss">
.theme--light.v-tabs > .v-tabs-bar {
    background-color: unset !important;
    border-bottom: 1px solid #ddd;
}

.theme--light.v-tabs-items {
    background-color: unset !important;
    padding: 1em;
}
</style>