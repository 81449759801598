import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import firebaseConfig from "../../config/firebase";

const db = firebase.firestore();

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export default {
  async fetchImage(imageId) {
    let response = await db
      .collection("media")
      .doc("database")
      .collection("images")
      .doc(imageId)
      .get();
    if (response && response.data()) {
      return response.data();
    } else return "";
  },
  uploadLogo: async (payload) => {
    // TODO remove old logo
    let image = payload.files[0];
    let newImage = {};

    newImage.createdAt = new Date();
    newImage.name = image.name;
    newImage.size = image.size;
    newImage.type = image.type;
    newImage.tenantId = payload.tenantId;

    try {
      const docRef = await db
        .collection("media")
        .doc("database")
        .collection("images")
        .add(newImage);

      const fileRef = `/media/tenant/${payload.tenantId}/${docRef.id}/${newImage.name}`;

      await firebase.storage().ref(fileRef).put(image);

      let url = await firebase.storage().ref(fileRef).getDownloadURL();

      let formattedImage = {
        id: docRef.id,
        url: url,
      };

      await db
        .collection("users")
        .doc("tenant")
        .collection("data")
        .doc(payload.tenantId)
        .update({
          tenantLogo: formattedImage,
        });

      return url;
    } catch (error) {
      console.log(error);
    }
  },
  upload: async (payload) => {
    let image = payload.files[0];
    let newImage = {};

    newImage.createdAt = new Date();
    newImage.name = image.name;
    newImage.size = image.size;
    newImage.type = image.type;
    newImage.artworkId = payload.artworkId;

    async function assignArtwork() {
      const docRef = await db
        .collection("media")
        .doc("database")
        .collection("images")
        .add(newImage);

      const fileRef = `/media/images/${payload.artworkId}/${docRef.id}/${newImage.name}`;

      await firebase.storage().ref(fileRef).put(image);

      let url = await firebase.storage().ref(fileRef).getDownloadURL();

      let formattedImage = {
        id: docRef.id,
        url: url,
      };

      const imageRef = db
        .collection("media")
        .doc("database")
        .collection("images")
        .doc(formattedImage.id);

      const artworkRef = db
        .collection("alcove")
        .doc("database")
        .collection("catalog")
        .doc(payload.artworkId);

      try {
        const response = await db.runTransaction(async (t) => {
          const artworkDoc = await t.get(artworkRef);

          t.update(artworkRef, {
            images: firebase.firestore.FieldValue.arrayUnion(formattedImage),
            updatedAt: new Date(),
          });

          t.update(imageRef, {
            updatedAt: new Date(),
            id: formattedImage.id,
          });
          return {
            images: artworkDoc.data().images,
            image: formattedImage,
          };
        });

        return response;
      } catch (e) {
        console.log("Transaction failure:", e);
      }
    }

    try {
      return await assignArtwork();
    } catch (error) {
      return console.log(error);
    }
  },
};
