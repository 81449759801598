<template>
    <div class="collection">
        <v-snackbar
        
        v-model="showMessage"
        :top="true"
        :timeout="1000"
        >
        saved 🎉

        </v-snackbar>
        <toolbar>
            <v-row class="d-flex align-center justify-end">
                <v-spacer></v-spacer>
                <v-col>
                    
                </v-col>
                    <v-btn
                        depressed
                        color="secondary" 
                        @click="save"
                        >
                        Save
                    </v-btn>
            </v-row>
        </toolbar>
        <v-dialog width="500px" v-model="chooseMore">
            <v-card class="pa-5 mx-auto dialog__card">
                <v-row v-for="(item, index) in catalog" :key="index">
                    <v-col cols="2">
                        <!-- TODO replace with thumbnail-->
                        <v-img v-if="item.images[0] && item.images[0]" :src="item.images[0].url" height="50px" width="50px">

                        </v-img>
                    </v-col>
                    <v-col cols="5" v-if="item.name">
                        {{item.name}}
                    </v-col>
                    <v-col cols="5" v-else>
                        Draft
                    </v-col>
                    <v-col v-if="collection.lineItems" class="d-flex justify-end">
                            <v-btn 
                            depressed 
                            @click="addToCollection(item)"
                            color="secondary"
                            text
                            v-if="!collection.lineItems.includes(item.id)"
                            >
                            add
                            <v-icon class="ml-2" small>
                                add_circle_outline
                            </v-icon>
                            </v-btn>
                            <v-btn 
                            depressed 
                            @click="removeFromCollection(item, index)"
                            color="error"
                            text
                            v-else
                            >
                            remove
                            <v-icon class="ml-2" small>
                                close
                            </v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card flat class="content-card hide-mobile">
                    <v-row>
                        <div class="overline mb-4">General</div>
                    </v-row>
                    <v-row class="mb-8">
                        <v-col>
                            <v-switch
                            color="secondary"
                            class="mr-6"
                            flat
                            inset
                            hide-details="true"
                            label="Published"
                            ></v-switch> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="6">
                            <v-text-field
                                v-model="collection.name"
                                label="Name"
                                required
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea height="180px" auto-grow label="Description" v-model="collection.description" outlined color="secondary">
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card flat class="content-card hide-mobile">
                    <v-row>
                        <div class="overline mb-8">Artwork</div>
                        <v-spacer></v-spacer>
                        <v-col class="align-right">
                            <v-btn 
                            depressed 
                            @click="chooseMore = true"
                            color="secondary"
                            >
                            Manage
                            <v-icon class="ml-2" small>
                                attachment
                            </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <draggable 
                        v-bind="dragOptions" 
                        v-model="works" 
                        draggable=".collection__artwork" 
                        handle=".collection__artwork-options"
                        @change="reorderImages"
                        >
                                <div 
                                class="collection__artwork"
                                :key="`${item.id}`"
                                v-for="(item) in works"
                                >
                                    <v-btn  
                                    depressed 
                                    small 
                                    fab
                                    color="#fff" 
                                    class="collection__artwork-options"
                                    >
                                        <v-icon color="secondary">
                                            drag_indicator
                                        </v-icon>
                                    </v-btn>
                                    <v-icon 
                                    class="collection__artwork-expand" 
                                    color="#fff"
                                    large
                                    >
                                        aspect_ratio
                                    </v-icon>
                                    <div v-if="item.images && item.images[0]" class="collection__artwork-image" :style="{'backgroundImage': `url(${item.images[0].url})`}">
                                    </div>
                                    <div class="collection__artwork-name">
                                        {{item.name}}
                                    </div>
                                </div>
                        </draggable>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import collectionService from '@/services/collectionService';
import catalogService from '@/services/catalog';
import { urls, routes } from '@/utils/constants';
import newCollection from '@/utils/collection';
import { catalog } from '../store/modules/catalog';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import Toolbar from '@/components/globals/Toolbar';
import clonedeep from 'lodash.clonedeep';
import AddNewImage from '@/components/images/AddNewImage';

    export default {
        data() {
            return {
                urls,
                showMessage: false,
                isLoading: false,
                isUploading: false,
                chooseMore: false,
                createMode: false,
                collection: {},
                works: []
            }
        },
        components: {
            draggable,
            Toolbar,
            AddNewImage
        },
        async created () {

            let id = this.$route.params.id;

            if (id === 'new') {
                this.collection = clonedeep(newCollection.schema());
                this.collection.tenantId = this.tenant.id;
                this.createMode = true;
            } else {
                
                this.collection = await collectionService.get(id);
                let params = {
                    id: this.collection.id,
                    lineItems: this.collection.lineItems
                }
                this.works = await catalogService.getCollectionCatalog(params);
                this.$store.dispatch('LOAD_CATALOG');
            }
                const breadcrumbs = {
                    items: [
                        {
                            text: 'Collections',
                            disabled: false,
                            exact: true,
                            to: {
                                name: routes.collections
                            }
                        },
                        {
                            text: this.collection.name ? this.collection.name : 'New',
                            disabled: true
                        }
                    ]
                };
                this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
        },
        methods: {
            async addToCollection(item) {
                let params;
                try {
                    let items = this.collection.lineItems;
                    items.push(item.id);
                    params = {
                        id: this.collection.id,
                        lineItems: items
                    }
                    this.works = await catalogService.getCollectionCatalog(params);
                    await collectionService.update(this.collection);
                } catch (error) {
                    console.log(error);
                }
            },
            async removeFromCollection(item, index) {
                let params;
                try {
                    let items = this.collection.lineItems;
                    let itemIndex = items.findIndex((element) => element === item.id);
                    items.splice(itemIndex, 1);

                    params = {
                        id: this.collection.id,
                        lineItems: items
                    }
                    this.works = await catalogService.getCollectionCatalog(params);
                    await collectionService.update(this.collection);
                } catch (error) {
                    console.log(error);
                }
            },
            async reorderImages() {
                let lineItems = [];
                this.works.forEach((item) => {
                    lineItems.push(item.id);
                });
                this.collection.lineItems = lineItems;
                await collectionService.update(this.collection);
            },
            async save() {
                try {
                    if (this.createMode) {
                        await collectionService.create(this.collection);
                    } else {
                        await collectionService.update(this.collection);
                    }
                    this.showMessage = true;
                } catch (error) {
                    console.log(error);
                }

            }

        },
        computed: {
            dragOptions () {
                return {
                    animation: 300,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost'
                };
            },
            ...mapGetters([
            'catalog',
            'tenant'
            ])
        }
    }
</script>

<style lang="scss" scoped>
.collection {
    &__artwork {
        width: 140px;
        height: auto;
        min-height: 140px;
        position: relative;
        float: left;
        margin: 0 10px 10px 0;
        border-radius: 10px;
        overflow: hidden;
    }
    &__artwork-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-color: #F5F6F8;
    }
    &__artwork-options {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
    }
    &__name {
        height: 40px;
        position: relative;
    }
}
.dialog {
    &__card {
        max-height: 500px;
        overflow: auto;
    }
}
</style>