<template>
    <div class="catalog">
        <v-card v-if="roles.canView" flat class="content-card hide-mobile" min-width="800px">
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="align-right">
                    <v-btn depressed @click="exportAll" color="#eee">Export 
                        <v-icon class="ml-2" small>
                            save_alt
                        </v-icon>
                    </v-btn>
                    <v-btn class="ml-2" depressed @click="$router.push('/artwork/new')" color="secondary">Add New
                        <v-icon class="ml-2" small>
                            add_circle_outline
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table
            :headers="headers"
            :items="catalog"
            :search="search"
            >
            <template v-slot:[`item.name`]="{ item }">
              <span v-if="item.name">
                  {{formatName(item.name)}}
              </span>
              <span v-else>
                  Draft
              </span>
            </template>

            <template v-slot:[`item.price`]="{ item }">
              <span v-if="item.price">
                  ${{item.price}}
              </span>
              <span v-else>
                  $0
              </span>
            </template>

            <template v-slot:[`item.isPublished`]="{ item }">
                <v-switch
                v-model="item.isPublished"
                @change="publishArtwork(item)"
                color="secondary"
                class="mr-6"
                flat
                inset
                hide-details="true"
                ></v-switch> 
            </template>

            <template v-slot:[`item.edit`]="{ item }">
                <v-row>
                    <v-btn @click="$router.push('/artwork/' + item.id)" fab x-small color="#f1f3f6" depressed>
                        <v-icon color="secondary">edit</v-icon>
                    </v-btn>

                    <v-btn class="ml-4" @click="deleteItem(item)" fab x-small color="#f1f3f6" depressed>
                        <v-icon color="#777">delete_outline</v-icon>
                    </v-btn>
                </v-row>
            </template>

            <template v-slot:[`item.images`]="{ item }">
                <v-btn @click="$router.push('/artwork/' + item.id)" icon raised rounded>
                    <v-img v-if="item.images[0]" max-width="35px" :src="item.images[0].url"></v-img>
                </v-btn>
            </template>

            </v-data-table>
        </v-card>

        <v-card
        class="content-card ma-1 hide-desktop"
        flat
        v-for="(item, index) in catalog"
        :key="index"
        >
            <div>
                <v-row>
                    <v-col class="col-6">
                        <v-img v-if="item.images[0]" max-width="100%" :src="item.images[0].url"></v-img>
                    </v-col>
                    <v-col class="col-6">
                        {{item.title}}
                        <br>
                        {{item.creator}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col class="align-right">
                        <v-btn
                        fab
                        depressed
                        color="secondary"
                        @click="$router.push('/edit-artwork/' + item.id)"
                        >
                            <v-icon>
                                edit
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>      
    </div>
</template>

<script>
import { routes } from '@/utils/constants';
import { store } from '../store';
import { mapGetters } from 'vuex';
import catalogService from '@/services/catalog';

var JSZip = require("jszip");
const axios = require("axios").default;

    export default {
        data() {
            return {
                src: 'https://firebasestorage.googleapis.com/v0/b/roxyart-io.appspot.com/o/catalog%2FVIBV5L5UbphDsoh3FK2k%2Fartwork-VIBV5L5UbphDsoh3FK2k.png?alt=media&token=ed470eef-86f1-4589-92bd-d254888fcdf9',
                routes: routes,
                search: '',
                headers: [
                { text: 'Published', value: 'isPublished' },
                { value: 'images', width: '80'},
                { text: 'Title', value: 'name' },
                { text: 'Artist/Creator', value: 'artist' },
                { text: 'Price', value: 'price' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Edit', value: 'edit', width: '120' }
                ]
            
            }
        },
       
        created(){
            store.dispatch('LOAD_CATALOG'); 
            const toolbarActions = {
                items: [
                    {
                        text: 'Add Artwork',
                        icon: 'add',
                        color: 'primary',
                        dark: true,
                        to: {
                            name: routes.artwork
                        }
                    }
                ]
            };
            this.$store.dispatch('SET_TOOLBAR_ACTIONS', toolbarActions);

            const breadcrumbs = {
                items: [
                    {
                        text: 'Catalog',
                        disabled: false,
                        exact: true
                    }
                ]
            };
            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);

        },
        methods: {
            formatName(name) {
                const MAX_LENGTH = 15;
                if (name.length > MAX_LENGTH) {
                    return `${name.slice(0, MAX_LENGTH)}...`;
                } else {
                    return name;
                }
            },
            publishArtwork(item) {
                catalogService.update(item);
            },
            thumbnailImage(payload){
                const prefix = payload.split('.');
                return prefix
            },  
            deleteItem(item) {
                // TODO Confirm delete and any db cleanup
                item.isDeleted = true;
                catalogService.update(item);
                location.reload();
            },
            async exportAll(){
                let sortedKeys = [];
                let headers = [];
                let rows = [];

                // this.catalog.forEach(item => {
                //     sortedKeys.push(item.name);
                //     headers.push(item);
                // });

                sortedKeys = [
                    'Name',
                    'Quantity',
                    'Creator'
                ]

                rows.push(sortedKeys);

                let csvContent = rows.map(e => e.join(",")).join("\n");
                let zip = new JSZip();

                zip.file(`alcove-inventory-catalog.csv`, csvContent);

                let result = await zip.generateAsync({type:"blob"})

                // TODO add better naming convenetion
                this.downloadBlob(result, `alcove_inventory`);


            },
            downloadBlob(data, filename){
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                return (() => {
                    var blob = data,
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                })()
            
            },
        },
        computed: {
            ...mapGetters([
            'catalog',
            'roles'
            ])
        }
    }
</script>

<style lang="scss" scoped>
    .catalog {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;

        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__table {
            margin: 0 auto 100px auto;
            width: 100%;
            max-width: 900px;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 10px 0px;
        }
    }

</style>
<style>
    .v-toolbar__content, .v-toolbar__extension {
        padding: 0 !important;
    }
</style>