export default {
    local: {
        get: (key) => {
            return localStorage.getItem(key);
        },
        set: (key, value) => {
            localStorage.setItem(key, value);
        },
        remove: (key) => {
            localStorage.removeItem(key);
        }
    },
    session: {
        get: (key) => {
            return sessionStorage.getItem(key);
        },
        set: (key, value) => {
            sessionStorage.setItem(key, value);
        },
        remove: (key) => {
            sessionStorage.removeItem(key);
        }
    }
};
