//import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Vue from "vue";
import router from "../../router";
import firebaseConfig from "../../../config/firebase";
import { initializeApp } from "firebase/app";
import { getFirestore,getDocs,collection, getDoc,doc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage";


//Vue.use(firebase);
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app)

const state = {
  catalog: [],
  artwork: {
    creator: "",
    title: "",
    year: "",
    medium: "",
    location: "",
    status: "",
    images: {},
    usdz: null,
    dimensions: {
      width: "",
      height: "",
      depth: "",
    },
    price: "",
    weight: "",
    forSale: "",
    checkUSDZ: true,
  },
};
const getters = {
  catalog: (state) => state.catalog,
  artwork: (state) => state.artwork,
};
const mutations = {
  loadCatalog(state, payload) {
    state.catalog = payload;
  },
  loadArtwork(state, payload) {
    state.artwork = payload;
  },
};
const actions = {
  async LOAD_CATALOG(context) {
    context.commit("loading");
    const catalogSnapshot = await getDocs(collection(db,"alcove","database","catalog"));
    const payload = [];
    catalogSnapshot.forEach((doc) => {
      if (!doc.data().isDeleted) {
        payload.push(doc.data());
      }
    });
    context.commit("loadCatalog", payload);
  },
  async CREATE_ARTWORK(context, payload) {
    context;
    const createdAt = new Date();
    let namePrefix = "artwork";
    await setDoc(doc(db,"alcove","database","catalog",payload.artwork))
      .then((docRef) => {
        return {
          updateRecord: storage
            .ref(
              "catalog/" +
                docRef.id +
                "/" +
                namePrefix +
                "-" +
                docRef.id +
                "." +
                payload.file.fileExt
            )
            .putString(payload.file.newUrl, "data_url")
            .then((data) => {
              data;
              storage
                .ref(
                  "catalog/" +
                    docRef.id +
                    "/" +
                    namePrefix +
                    "-" +
                    docRef.id +
                    "." +
                    payload.file.fileExt
                )
                .getDownloadURL()
                .then((url) => {
                  updateDoc(doc(db,"alcove","database","catalog",docRef.id, {
                    photoURL: url,
                      id: docRef.id,
                      filename:
                        namePrefix +
                        "-" +
                        docRef.id +
                        "." +
                        payload.file.fileExt,
                      createdAt: createdAt,
                      checkUSDZ: true,
                  }))
                })
                .catch((error) => {
                  console.error(error);
                });
            }),
          changeRoute: router.push("/catalog"),
        };
      });
  },
  async LOAD_ARTWORK(context, payload) {
    let artworkSnap = {};
    try {
      const artworkRef = doc(db,"alcove","database","catalog",payload)
      artworkSnap = await getDoc(artworkRef)
    } catch (error) {
      console.log(error);
    }
    return artworkSnap.data();
  },
};

export const catalog = {
  state,
  mutations,
  actions,
  getters,
};
