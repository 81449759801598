<template>
    <div class="contact">
        <v-snackbar
        
        v-model="showMessage"
        :top="true"
        :timeout="1000"
        >
        saved 🎉

        </v-snackbar>
        <EditContact
        v-if="editMode"
        :contact="contact"
        v-on:save="save"
        v-on:create="create"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { routes } from '@/utils/constants';
import EditContact from '@/components/contact/EditContact';
import contactService from '@/services/contactService';
import contactSchema from '@/utils/contact';

export default {
    data() {
        return {
            editMode: false,
            id: '',
            showMessage: false,
            contact: contactSchema.schema()
        }
    },
    created() {
        this.loadContact();
    },
    components: {
        EditContact
    },
    computed: {
        ...mapGetters([
        'roles',
        'tenant'
        ])
    },
    methods: {
        async save(contact) {
            try {
                await contactService.update(contact);
                this.showMessage = true;
            } catch (error) {
                console.log(error);
            }
        },
        async create(contact) {
            contact.tenantId = this.tenant.id;
            let contactId = await contactService.create(contact);
            if (contactId && contactId.length) {
                this.$router.push({
                    name: routes.contacts
                })
            }
        },
        async loadContact() {
            let breadcrumbs = {};
            this.editMode = this.$route.meta.editMode;

            try {
                if (this.editMode){
                    this.id = this.$route.params.id;

                    if (this.id && this.id !== 'new') {
                        this.contact = await contactService.get(this.id);
                    } else {
                        this.contact.id = 'new';
                    }

                    breadcrumbs = {
                            items: [
                            {
                                text: 'Contacts',
                                disabled: false,
                                exact: true,
                                to: {
                                    name: routes.contacts
                                }
                            },
                            {
                                text: this.contact.name ? this.contact.name : 'Draft',
                                disabled: true
                            }
                        ]
                    }
                } else {
                    breadcrumbs = {
                            items: [
                                {
                                    text: 'Contacts',
                                    disabled: false,
                                    exact: true,
                                    to: {
                                        name: routes.contacts
                                    }
                                },
                                {
                                    text: 'New',
                                    disabled: true,
                                    exact: true,

                                }
                        ]
                    }
                    let contactId = await contactService.create(contactSchema.schema());
                    this.$router.push(`contact/${contactId}`);
                }
            } catch (error) {
                console.log(error)
            }

            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);

        }
    }
}
</script>

<style lang="scss" scoped>
    .contact {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 100px;


        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__card {
            margin: 1em auto;
            width: 100%;
            max-width: 900px;
            padding: 2em;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 3px 0px;
        }

    }
</style>