<template>
    <div class="contacts">
        <v-card v-if="roles.canView" flat class="content-card hide-mobile" min-width="800px">
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="align-right">
                    <v-btn class="ml-2" depressed @click="$router.push({name: routes.contact, params: { id: 'new' }})" color="secondary">Add New
                        <v-icon class="ml-2" small>
                            add_circle_outline
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table
            v-if="contacts && contacts.length"
            :headers="headers"
            :items="contacts"
            :search="search"
            >
            <template v-slot:item.name="{ item }">
              <span v-if="item.name">
                  {{item.name}}
              </span>
              <span v-else>
                  Draft
              </span>
            </template>

            <template v-slot:item.edit="{ item }">
                <v-row>
                    <v-btn @click="$router.push({name: routes.contact, params: { id: item.id }})" fab x-small color="#f1f3f6" depressed>
                        <v-icon color="secondary">edit</v-icon>
                    </v-btn>

                    <v-btn class="ml-4" @click="deleteItem(item)" fab x-small color="#f1f3f6" depressed>
                        <v-icon color="#777">delete_outline</v-icon>
                    </v-btn>
                </v-row>
            </template>

            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import contactService from '@/services/contactService';
import Toolbar from '@/components/globals/Toolbar';
import { routes } from '@/utils/constants';

    export default {
        async created () {
            const breadcrumbs = {
                items: [
                    {
                        text: 'Contacts',
                        disabled: false,
                        exact: true
                    }
                ]
            };
            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
            this.contacts = await contactService.getContacts(this.tenant.id);
        },
        data() {
            return {
                src: 'https://firebasestorage.googleapis.com/v0/b/roxyart-io.appspot.com/o/catalog%2FVIBV5L5UbphDsoh3FK2k%2Fartwork-VIBV5L5UbphDsoh3FK2k.png?alt=media&token=ed470eef-86f1-4589-92bd-d254888fcdf9',
                routes: routes,
                contacts: [],
                search: '',
                headers: [
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Phone Number', value: 'phoneNumber' },
                { text: 'Edit', value: 'edit', width: '120' }
                ]
            }
        },
        methods: {
            async deleteItem(item) {
                // TODO Confirm delete and any db cleanup
                item.isDeleted = true;
                await contactService.update(item);
                this.contacts = await contactService.getContacts(this.tenant.id);
            },
        },
        components: {
            Toolbar
        },
        computed: {
            ...mapGetters([
            'roles',
            'tenant'
            ])
        }
    }
</script>

<style lang="scss" scoped>

</style>