<template>
    <div class="artwork">
        <toolbar>
            <v-row class="d-flex align-center justify-end">
                <v-spacer></v-spacer>
                <v-col>
                </v-col>
                    <v-btn
                        depressed
                        color="secondary" 
                        @click="save"
                        >
                        Save
                    </v-btn>
            </v-row>
        </toolbar>
        <template v-if="isLoaded">
            <v-card class="artwork__card" flat>
                <v-row>
                    <div class="overline mb-4">General</div>
                </v-row>
                <v-row class="mb-8">
                    <v-col>
                        <v-switch
                        v-model="artwork.isPublished"
                        color="secondary"
                        class="mr-6"
                        flat
                        inset
                        hide-details="true"
                        label="Published"
                        ></v-switch>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center justify-end">
                        <a :href="`${urls.artwork}/${artwork.id}`" target="_blank" rel="noopener noreferrer">
                            <v-btn 
                            color="secondary"
                            depressed
                            text
                            >
                                view artwork
                                <v-icon class="ml-4">
                                    open_in_new
                                </v-icon>
                            </v-btn>
                        </a>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="6">
                        <v-text-field
                            v-model="artwork.name"
                            label="Title"
                            required
                            color="secondary"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-6">
                        <v-text-field
                            v-model="artwork.price"
                            label="Price"
                            required
                            color="secondary"
                            prefix="$"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-textarea
                            v-model="artwork.artist"
                            label="Artist/Creator"
                            required
                            color="secondary"
                        ></v-textarea>
                    </v-col>
                    <v-col class="col-6">
                        <v-text-field
                            v-model="artwork.quantity"
                            label="Quantity"
                            required
                            type="number"
                            color="secondary"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="artwork.description"
                            label="Description"
                            required
                            outlined
                            color="secondary"
                            class="mt-4"
                            auto-grow
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="artwork__card" flat>
                <v-row>
                    <div class="overline mb-4">Images</div>
                    <v-spacer></v-spacer>
                </v-row>
                <EditImage v-on:sync-images="syncImages" v-if="artwork.id" :artwork="artwork"/>
            </v-card>
            <v-card class="artwork__card" flat>
                <v-row>
                    <div class="overline mb-4">Dimensions</div>
                    <v-spacer></v-spacer>
                <v-btn fab text x-small>
                        <v-icon color="grey">settings</v-icon>
                    </v-btn>
                    
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="artwork.dimensions.width"
                            @change="updateCanvasXr"
                            label="Width"
                            required
                            suffix='"'
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="artwork.dimensions.height"
                            @change="updateCanvasXr"
                            label="Height"
                            required
                            suffix='"'
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="artwork.dimensions.depth"
                            @change="updateCanvasXr"
                            label="Depth"
                            required
                            suffix='"'
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="artwork__card cxr-card" flat>
                <v-row>
                    <div class="overline mb-4">CanvasXR</div>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row>
                    <div class="artwork__sub-text">
                        <p>
                            <strong>CanvasXR</strong> turns canvas art into 3D models. You can display that artwork in the real world through any mobile device.<br> 
                            Select a cropped image of your work and be sure to add your dimensions above.<br>
                            <strong v-if="!hasImages"><br>Please upload an image above to get started.</strong>
                        </p>
                    </div>
                </v-row>
                <v-row v-if="hasImages" class="pt-2">
                    <v-col
                        cols="2" v-for="(image, index) in artwork.images"
                        :key="index"
                    >
                        <v-img class="canvas-xr-select" @click="selectCanvas(image)" :src="image.url" width="100px" height="100px"></v-img>
                    </v-col>
                </v-row>

                <div>
                    <v-row class="mt-8">
                        <v-col>
                            <div class="artwork__canvas-xr-container" v-if="hasImages && this.artwork.canvasxrURL && this.artwork.canvasxrURL.length">
                                <canvas-xr
                                    :image-src="this.artwork.canvasxrURL"
                                    :show-model="true"
                                    :key="canvasKey"
                                    :canvas-width="this.artwork.dimensions.width"
                                    :canvas-height="this.artwork.dimensions.height"
                                    :canvas-depth="this.artwork.dimensions.depth"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card class="artwork__card" flat>
                <v-row>
                    <div class="overline mb-4">Commerce</div>
                    <v-spacer></v-spacer>
                <v-btn fab text x-small>
                        <v-icon color="grey">settings</v-icon>
                    </v-btn>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="artwork.price"
                            label="Price"
                            required
                            prefix="$"
                            color="secondary"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="artwork.weight"
                            label="Weight"
                            required
                            suffix="lbs"
                            color="secondary"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="artwork.isforSale"
                            label="For Sale"
                            required
                            color="secondary"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="artwork.purchaseLink"
                            label="Purchase link to artwork"
                            required
                            prepend-icon="link"
                            color="secondary"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="artwork__card" flat>
                <v-row>
                    <div class="overline mb-4">Details</div>
                    <v-spacer></v-spacer>
                </v-row>

                <v-row>
                    <v-col cols="6" md="6">
                        <v-text-field
                            v-model="artwork.year"
                            label="Year"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-text-field
                            v-model="artwork.medium"
                            label="Medium"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-text-field
                            v-model="artwork.location"
                            label="Location"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Toolbar from '@/components/globals/Toolbar';
import EditImage from '@/components/globals/EditImage';
import { urls } from '@/utils/constants';
import isEmpty from 'lodash.isempty';

    export default {
        data() {
            return {
                urls,
                file: {
                    fileExt: 'jpg',
                    newUrl: ''
                },
                imageUrl: '',
                image: 'image',
                statuses: [
                    'published',
                    'draft'
                ],
                canvasKey: 0
            }
        },
        components: {
            Toolbar,
            EditImage
        },
        props: ['artwork'],
        computed: {
            ...mapGetters([
                'collections'
            ]),
            isLoaded() {
                return !isEmpty(this.artwork);
            },
            hasImages() {
                return this.artwork && this.artwork.images && this.artwork.images.length;
            },
            hasCanvasXRModel() {
                return this.artwork && this.artwork.canvasxrURL && this.artwork.canvasxrURL.length;
            }
        },
        methods: {
            save() {
                this.showMessage = true;
                this.$emit('save', this.artwork);
            },
            selectCanvas(image) {
                this.artwork.canvasxrURL = image.url;
                this.canvasKey = Date.now()
                this.$forceUpdate();
            },
            updateCanvasXr() {
                this.canvasKey = Date.now();
            },
            onFilePicked(event){
                const files = event
                let filename = files.name
                this.file.fileExt = filename.substr(filename.lastIndexOf('.') + 1)

                if (filename.lastIndexOf(".") <= 0) {
                    return alert("Please add a valid file type.");
                }
                const fileReader = new FileReader()
                fileReader.addEventListener("load", () => {
                    this.imageUrl = fileReader.result;
                    this.file.newUrl = fileReader.result;
                })
                fileReader.readAsDataURL(files)
                this.image = files;
            },
            syncImages(images){
                this.artwork.images = images;
            }
        }
    }
</script>
<style>
.v-input--selection-controls {
    margin: 0;
}
</style>
<style lang="scss" scoped>
    .artwork {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 100px;


        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__card {
            margin: 1em auto;
            width: 100%;
            max-width: 900px;
            padding: 2em;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 3px 0px;
        }
        &__status {
            max-width: 250px;
        }
        &__sub-text {
            font-size: 14px;
            color: #555;
            background-color: #f9f9f9;
            padding: 1em 1em 0 1em;
            text-align: left;
            width: 100%;
        }
        &__canvas-xr-container {
            position: relative;
            width: 100%;
            height: 500px;
            background: radial-gradient(circle at center, #fff 0, #fcfcfc, #dddddd 100%);
        }
        &__canvas-xr-select {
            cursor: pointer;
        }
    }
</style>