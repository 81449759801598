import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import Vue from "vue";
import { deployment } from "@/deployment.js";
import firebaseConfig from "../../../config/firebase";

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

Vue.use(firebase);
const db = firebase.firestore();

const state = {
  users: [],
};
const getters = {
  users: (state) => state.users,
};
const mutations = {
  emptyUsers(state) {
    state.users = [];
  },
  loadUsers(state, payload) {
    state.users = payload;
  },
};
const actions = {
  LOAD_USERS(context, tenantId) {
    const payload = [];
    context.commit("emptyUsers", payload);
    db.collection("users")
      .doc("user")
      .collection("data")
      .where("tenants", "array-contains", tenantId)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.forEach((doc) => {
          payload.push(doc.data());
        });
      });
    context.commit("loadUsers", payload);
  },
  LOAD_USERS(context, tenantId) {
    const payload = [];
    context.commit("emptyUsers", payload);
    db.collection("users")
      .doc("user")
      .collection("data")
      .where("tenants", "array-contains", tenantId)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.forEach((doc) => {
          payload.push(doc.data());
        });
      });
    context.commit("loadUsers", payload);
  },
  async CREATE_USER(context, payload) {
    const timestamp = new Date();
    payload.roles = {};
    payload.roles.permissions = payload.permissions;
    payload.roles.tenantId = payload.onboard;
    payload.roles.domain = "alcove";
    payload.roles.status = "REQUESTED";

    const tenantUser = {
      createdAt: timestamp,
      updatedAt: timestamp,
      email: payload.email,
      roles: [payload.roles],
      tenants: [payload.onboard],
    };
    await db
      .collection("users")
      .doc("user")
      .collection("data")
      .add(tenantUser)
      .then((doc) => {
        var actionCodeSettings = {
          url: `${deployment.loginUrl}/register?onboard=${payload.onboard}&userId=${doc.id}`,
          handleCodeInApp: true,
        };

        context.dispatch("LOAD_USERS", payload.onboard);
        firebase
          .auth()
          .sendSignInLinkToEmail(payload.email, actionCodeSettings)
          .then(() => {
            window.localStorage.setItem("emailForSignIn", payload.email);
          })
          .catch(function (error) {});
      });
  },
};

export const users = {
  state,
  mutations,
  actions,
  getters,
};
