<template>
  <div class="navigation">
        <v-list
          nav
          color="primary"
          dark
          class="navigation__list"
        >
          
          <v-list-item>

            <v-list-item-content>
              <v-list-item-title v-if="tenant.name">{{tenant.name}}</v-list-item-title>              
            </v-list-item-content>
            <v-divider  vertical></v-divider>
            <v-btn fab text color="primary" class="settings" @click="$router.push({name: routes.settings})">
                <v-icon color="accent">settings</v-icon>
            </v-btn>
          </v-list-item>

            <v-divider class="mb-4"></v-divider>
            <v-list-item-group>
              <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                active-class="active-list-item"
                @click="$router.push(item.link)"
              >
                <v-list-item-icon>
                  <v-icon color="accent">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-item-group>
        </v-list>
                <v-img class="bottom" src="../../../static/alcove-logo.svg"></v-img>
  </div>
</template>

<script>
import { routes } from '@/utils/constants';

  export default {
    props: ['user', 'tenant'],
    data () {
      return {
        routes,
        drawer: true,
        items: [],
        links: [
          { title: 'Overview', icon: 'bar_chart', link: '/', permissions: true},
          { title: 'Catalog', icon: 'library_books', link: '/catalog', permissions: true},
          { title: 'Collections', icon: 'style', link: '/collections', permissions: true},
          { title: 'Marketing', icon: 'storefront', link: '/marketing', permissions: true},
          { title: 'Contacts', icon: 'supervisor_account', link: '/contacts', permissions: true},
          { title: 'Platform', icon: 'enhanced_encryption', link: '/platform', permissions: this.isPlatform()}
        ],
        color: 'primary',
        colors: [
          'primary',
          'blue',
          'success',
          'red',
          'teal',
        ],
        miniVariant: false,
        expandOnHover: false,
        background: false,
      }
    },
    created () {
      for (var index in this.links) {
        if (this.links[index].permissions) {
            this.items.push(this.links[index])
        }
      }
    },
    methods: {
      isPlatform() {
        return this.tenant.isPlatform;
      }
    }
  }
</script>
<style lang="scss">
.active-list-item {
  color: rgba(255, 255, 255, 0.3);
}
.v-list-item__title {
  color: #fff;
}
</style>
<style lang="scss" scoped>
.navigation {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  &__list {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
.bottom {
    width: 160px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -85px;
}
.settings {
    margin-left: 1em;
}
</style>