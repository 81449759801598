<template>
    <v-card flat class="content-card">
        <v-row>
            <div class="overline mb-4">General</div>
        </v-row>
        <v-row>
            <v-col cols="6" md="6">
                <v-text-field
                    v-model="tenant.name"
                    label="Name"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="6">
                <AddNewImage 
                :thumbnail="tenant.tenantLogo.url"
                :isUploading="isUploading"
                v-on:upload-file="uploadLogo"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AddNewImage from '@/components/images/AddNewImage';
import imageService from '@/services/images';

    export default {
        data() {
            return {
                isUploading: false
            }
        },
        computed: {
            ...mapGetters([
            'user',
            'tenant'
            ])
        },
        methods: {
            async uploadLogo(file) {
                try {
                    this.isUploading = true;
                    let params = {
                        files: [file], 
                        tenantId: this.tenant.id
                    }
                    
                    let response = await imageService.uploadLogo(params);
                    this.tenant.tenantLogo.url = response; 
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isUploading = false;
                }


                this.$forceUpdate()

            }
        },
        components: {
            AddNewImage
        }
    }
</script>

<style lang="scss" scoped>

</style>