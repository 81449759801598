<template>
    <div id="image-viewer" class="image-viewer" :style="{'backgroundImage': `url(${image.url})`}">
        <div class="image-viewer__cover"></div>
        <div class="image-viewer__toolbar">
            <v-row>
                <v-col class="align-left">
                    <v-btn
                    fab
                    class="ml-4"
                    @click="close"
                    >
                        <v-icon>
                            close
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="d-flex justify-end align-center">
                    <v-btn @click="nextOption('left')" dark text fab>
                        <v-icon>
                            west
                        </v-icon>
                    </v-btn>
                    <v-btn class="ml-4 mr-8" dark text fab @click="nextOption('right')">
                        <v-icon>
                            east
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div class="image-viewer__container">
            <div class="image-viewer__image-container">
                <v-img class="image-viewer__image" width="100%" :src="image.url"></v-img>
            </div>
            <div class="image-viewer__panel">
                <v-col class="d-flex align-start justify-start pa-0 pb-4">
                        {{loadedArtwork.name}}
                </v-col>
                <v-textarea
                outlined
                dark
                label="Image Description"
                >

                </v-textarea>
                <v-row class="pa-0">
                    <v-col class="align-left pt-0">
                        <v-btn @click="deleteItem(image)" dark color="error">
                            Delete
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row v-if="isLoading" class="d-flex justify-center align-center">
                    <v-progress-circular width="3" indeterminate size="100" color="#fff"></v-progress-circular>
                </v-row>
                <v-col v-else>
                    <v-row v-if="isArtworkLoaded 
                    && loadedArtwork.detect[0].imagePropertiesAnnotation
                    && loadedArtwork.detect[0].imagePropertiesAnnotation.dominantColors.colors">
                        <v-col class="image-viewer__label">
                            Colors
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="isArtworkLoaded 
                    && loadedArtwork.detect[0].imagePropertiesAnnotation
                    && loadedArtwork.detect[0].imagePropertiesAnnotation.dominantColors.colors"
                    >
                        <div 
                        v-for="(color, index) in loadedArtwork.detect[0].imagePropertiesAnnotation.dominantColors.colors" 
                        :key="index" class="image-viewer__swatch"
                        :style="{backgroundColor: `rgb(${color.color.red}, ${color.color.green}, ${color.color.blue})`}"
                        >
                        </div>
                    </v-row>

                    <v-row v-if="isArtworkLoaded 
                    && loadedArtwork.detect[0].landmarkAnnotations
                    && loadedArtwork.detect[0].landmarkAnnotations.length">
                        <v-col class="image-viewer__label">
                            Landmark
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="isArtworkLoaded 
                    && loadedArtwork.detect[0].landmarkAnnotations
                    && loadedArtwork.detect[0].landmarkAnnotations.length"
                    >
                        <div 
                        v-for="(landmark, index) in loadedArtwork.detect[0].landmarkAnnotations" 
                        :key="index" class="image-viewer__tag"
                        >
                        <v-icon dark>
                            place
                        </v-icon>
                        {{landmark.description}}
                        </div>
                    </v-row>


                    <v-row v-if="isArtworkLoaded && loadedArtwork.detect[0].labelAnnotations">
                        <v-col class="image-viewer__label">
                            Tags
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="isArtworkLoaded && loadedArtwork.detect[0].labelAnnotations">
                        <div v-for="(tag, index) in loadedArtwork.detect[0].labelAnnotations" :key="index" class="image-viewer__tag">
                            <v-icon dark>
                                local_offer
                            </v-icon>
                            <template v-if="tag.description">
                                {{tag.description}}
                            </template>
                        </div>
                    </v-row>

                    <v-row class="mt-4" v-if="isArtworkLoaded && loadedArtwork.detect[0].safeSearchAnnotation">
                        <v-col class="image-viewer__label">
                            Adult Content
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="isArtworkLoaded && loadedArtwork.detect[0].safeSearchAnnotation">
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                lock
                            </v-icon>
                            Adult: {{loadedArtwork.detect[0].safeSearchAnnotation.adult}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                lock
                            </v-icon>
                            Medical: {{loadedArtwork.detect[0].safeSearchAnnotation.medical}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                lock
                            </v-icon>
                            Racy: {{loadedArtwork.detect[0].safeSearchAnnotation.racy}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                lock
                            </v-icon>
                            Spoof: {{loadedArtwork.detect[0].safeSearchAnnotation.spoof}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                lock
                            </v-icon>
                            Violence: {{loadedArtwork.detect[0].safeSearchAnnotation.adult}}
                        </div>
                    </v-row>

                    <v-row v-if="isArtworkLoaded && loadedArtwork.detect[0].textAnnotations.length">
                        <v-col class="image-viewer__label">
                            Text
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="isArtworkLoaded && loadedArtwork.detect[0].textAnnotations">
                        <div v-for="(text, index) in loadedArtwork.detect[0].textAnnotations" :key="index" class="image-viewer__tag">
                            <v-icon dark>
                                text_fields
                            </v-icon>
                            <template v-if="text.description">
                                {{text.description}}
                            </template>
                        </div>
                    </v-row>

                    <v-row class="mt-4" v-if="isArtworkLoaded && loadedArtwork.detect[0].faceAnnotations && loadedArtwork.detect[0].faceAnnotations.length">
                        <v-col class="image-viewer__label">
                            Face Annotations
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-if="isArtworkLoaded && loadedArtwork.detect[0].faceAnnotations && loadedArtwork.detect[0].faceAnnotations.length">
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                tag_faces
                            </v-icon>
                            Angry: {{loadedArtwork.detect[0].faceAnnotations[0].angerLikelihood}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                tag_faces
                            </v-icon>
                            Joy: {{loadedArtwork.detect[0].faceAnnotations[0].joyLikelihood}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                tag_faces
                            </v-icon>
                            Sorrow: {{loadedArtwork.detect[0].faceAnnotations[0].sorrowLikelihood}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                tag_faces
                            </v-icon>
                            Headwear: {{loadedArtwork.detect[0].faceAnnotations[0].headwearLikelihood}}
                        </div>
                        <div class="image-viewer__tag">
                            <v-icon dark>
                                tag_faces
                            </v-icon>
                            Blurred: {{loadedArtwork.detect[0].faceAnnotations[0].blurredLikelihood}}
                        </div>
                    </v-row>
                </v-col>
            </div>
        </div>
    </div>
</template>

<script>
import images from '@/services/images';
const axios = require('axios').default;

    export default {
        props: ['image'],
        data() {
            return {
                url: '',
                fileName: '',
                isLoading: false,
                loadedArtwork: {}
            }
        },
        async created() {
            this.fetchImageData();
        },
        methods: {
            close(){
                this.$emit('close-viewer');
            },
            nextOption(direction){
                this.fetchImageData();
                this.$emit('next-option', direction);
            },
            deleteItem(item){
                this.$emit('delete-item', item);
            },
            async downloadPDF(){
                try {
                    const response = await axios.get(`http://localhost:5001/roxyart-io/us-central1/auth-api/pdf?url=${this.url}&fileName=${this.fileName}`)

                    if (response && response.data) {
                        window.open(response.data, '_blank', 'fullscreen=yes');
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async fetchImageData(){
                this.isLoading = true
                try {
                    this.loadedArtwork = await images.fetchImage(this.image.id);
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        computed: {
            isArtworkLoaded(){
                return this.loadedArtwork.detect && this.loadedArtwork.detect[0];
            }
        }
    }
</script>

<style lang="scss" scoped>
.image-viewer {
    color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background-color: #222;
    left: 0;
    top: 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: none;
    background-size: 120%;
    &__cover {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .8);
        backdrop-filter: blur(20px);
        z-index: 0;
    }
    &__toolbar {
        width: 100%;
        height: 80px;
        background-color: #333;
        position: relative;
        z-index: 1;
    }
    &__container {
        width: 100%;
        display: flex;
    }
    &__image-container {
        width: calc(100% - 400px);
        height: 100vh;
        overflow-y: auto;
        background-position: center;
    }
    &__image {
        max-width: 600px;
        margin: 20px auto 200px auto;
    }
    &__panel {
        width: 400px;
        height: 100vh;
        background-color: #111;
        padding: 20px;
        padding-bottom: 100px;
        position: relative;
        z-index: 1;
        overflow: auto;
    }
    &__label {
        color: #fff;
        text-align: left;
    }
    &__swatch {
        width: 50px;
        height: 50px;
        color: #fff;
        border-radius: 50%;
        margin: 5px 5px 5px 0;
    }
    &__tag {
        border: 1px solid #fff;
        color: #fff;
        padding: 5px 10px;
        margin: 5px;
    }
}
</style>