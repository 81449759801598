import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth';
import { toolbarActions } from './modules/toolbarActions';
import { users } from './modules/users';
import { catalog } from './modules/catalog';
import { collections } from './modules/collections';
import { breadcrumbs } from './modules/breadcrumbs';

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    toolbarActions,
    users,
    breadcrumbs,
    catalog,
    collections
  }
});