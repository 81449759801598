<template>
    <div class="artwork">
        <v-toolbar class="artwork__toolbar" flat>
            <h1>{{pageTitle}}</h1>
            <v-spacer></v-spacer>
            <model-viewer ar ar-scale="fixed" quick-look-browsers="safari chrome" id="environment-intensity-demo" ref="modelV" preload class="embed" :poster="catalog[0].photoURL" :ios-src="catalog[0].usdz" :alt="catalog[0].title" camera-controls background-color="#fff">
            <button slot="ar-button" class="ar-button">
                👋
            </button>
            </model-viewer>
        </v-toolbar>
    </div>
</template>

<script>
import { store } from '../store'
import { mapGetters } from 'vuex'
store;

    export default {
        data() {
            return {
                editMode: false,
                pageTitle: null,
                id: null,
                createArtwork: {
                    creator: '',
                    title: '',
                    year: '',
                    medium: '',
                    location: '',
                    status: '',
                    images: {},
                    usdz: null,
                    dimensions: {
                        width: '',
                        height: '',
                        depth: ''
                    },
                    price: '',
                    weight: '',
                    forSale: '',
                    checkUSDZ: true
                }
            }
        },
        created() {

            this.editMode = this.$route.meta.editMode;
            if (this.editMode){
                this.id = this.$route.params.id;
                if (this.id !== null){
                    store.dispatch('LOAD_ARTWORK', this.id);
                }
                this.pageTitle = `${this.artwork.title}`;
            } else {
                this.pageTitle = 'Add Artwork'
            }
        },
        computed: {
            ...mapGetters([
                'collections',
                'catalog',
                'artwork'
            ])
        },

        methods: {
            save() {
                let payload = {
                    artwork: this.artwork,
                    file: this.file
                }
                store.dispatch('CREATE_ARTWORK', payload)
            },

        }
    }
</script>

<style lang="scss" scoped>
    .artwork {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 100px;


        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__card {
            margin: 1em auto;
            width: 100%;
            max-width: 900px;
            padding: 2em;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 3px 0px;
        }

    }
</style>