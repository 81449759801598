import Vue from "vue";
import App from "./App.vue";

import Vuex from "vuex";
import { store } from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Chart from "chart.js";
import "@google/model-viewer";
// import VueQrcode from "vue-qrcode";

Vue.use(Vuex);
// Vue.use(VueQrcode);

Vue.config.productionTip = true;

new Vue({
  render: (h) => h(App),
  store,
  router,
  vuetify,
  Chart,
  components: { App },
}).$mount("#app");
