export default {
    schema: () => {
        return {
            name: '',
            description: '',
            analytics: {
                facebook: {
                    pixelId: '',
                    isActive: false
                },
                google: {
                    trackingId: '',
                    isActive: false
                }
            },
            createdAt: '',
            createdBy: '',
            contact: {
                phoneNumber: '',
                email: '',
                address: ''
            },
            id: '',
            updatedAt: '',
            updatedBy: '',
            showAugmentedReality: true,
            showColor: true,
            showPrice: true,
            showQRCode: false,
            forceConvert: false,
            lineItems: [],
            icon: {},
            isPublished: false,
            isDeleted: false,
            startPublishedDate: '',
            endPlublishedDate: '',
            publishedDuration: '',
            siteLogo: {},
            socialLinks: {},
            tenantId: '',
            urlPath: '',
            version: 1
        }
    }
}