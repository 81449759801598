<template>
    <div class="image-tools">
        <ImageViewer 
        v-if="isImageEditMode" 
        :image="activeImage" 
        v-on:close-viewer="closeViewer"
        v-on:next-option="nextOption"
        v-on:delete-item="deleteItem"
        />
        <div class="image-tools__rows">
            <template v-if="hasImages">
                <div class="image-tools__row-main">
                    <div class="image-tools__image main-image">
                        <div class="image-tools__image-container" :style="{'backgroundImage': `url(${this.images[0].url})`}">
                        </div>
                    </div>
                </div>
            
                <draggable 
                v-bind="dragOptions" 
                class="image-tools__row"  
                v-model="images" 
                draggable=".image-tools__image" 
                handle=".image-tools__options"
                @change="reorderImages"
                >
                        <div 
                        class="image-tools__image"
                        :key="`${item.id}`"
                        v-for="(item, index) in images"
                        @click="editImage(item, index)"
                        >
                            <v-btn  
                            depressed 
                            small 
                            fab
                            color="#fff" 
                            class="image-tools__options"
                            >
                                <v-icon color="secondary">
                                    drag_indicator
                                </v-icon>
                            </v-btn>
                            <v-icon 
                            class="image-tools__expand" 
                            color="#fff"
                            large
                            >
                                aspect_ratio
                            </v-icon>
                            <div class="image-tools__image-container" :style="{'backgroundImage': `url(${item.url})`}">
                            </div>
                        </div>
                        <vue-dropzone 
                        ref="myVueDropzone" 
                        id="dropzone"
                        @vdropzone-thumbnail="fileAdded"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        :includeStyling="false"
                        slot="footer"
                        role="group"
                        aria-label="Basic example"
                        key="footer"
                        >
                            <div class="image-tools__image-container no-touch-events">
                                <template v-if="!isLoading">
                                    Add New Image 
                                    <v-icon class="ml-1" small color="secondary">add</v-icon>
                                </template>
                                <template v-else>
                                    <v-progress-circular width="3" indeterminate size="50" color="secondary"></v-progress-circular>
                                </template>
                            </div>
                        </vue-dropzone>
                </draggable>
            </template>
            <div class="image-tools__row" v-else>
                    <vue-dropzone 
                    ref="myVueDropzone" 
                    id="dropzone"
                    @vdropzone-thumbnail="fileAdded"
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    :includeStyling="false"
                    >
                    <div class="dropzone-container">
                        <div class="image-tools__image-container no-touch-events">
                            <template v-if="!isLoading">
                                Add New Image 
                                <v-icon class="ml-1" small color="secondary">add</v-icon>
                            </template>
                            <template v-else>
                                <v-progress-circular width="3" indeterminate size="50" color="secondary"></v-progress-circular>
                            </template>
                        </div>
                    </div>
                </vue-dropzone>
            </div>
        </div>
    </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import draggable from 'vuedraggable';
import images from '@/services/images';
import ImageViewer from '@/components/images/ImageViewer';
import catalogService from '@/services/catalog';
import clonedeep from 'lodash.clonedeep';
import { store } from '@/store';

const MAX_FILE_SIZE = 2;

export default {
    data() {
        return {
            isLoading: false,
            isImageEditMode: false,
            activeImage: {},
            images: [],
            editMenu: [
                {
                    text: 'Edit',
                    icon: 'edit'
                },
                {
                    text: 'Delete',
                    icon: 'close'
                }
            ],
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { "My-Awesome-Header": "header value" },
                createImageThumbnails: "false",
                acceptedFiles: "image/*",
                uploadMultiple: false
            }
        }
    },
    props: ['artwork', 'image'],
    watch: {
        images(images) {
            this.$emit('sync-images', images);
        }
    },
    created() {
        this.images = clonedeep(this.artwork.images);
    },
    methods: {
        async editImage(image, index){
            this.isImageEditMode = true;
            this.activeImage = image;
            this.activeImage.index = index;
        },
        closeViewer(){
            this.isImageEditMode = false;
        },
        nextOption(direction) {
    
            if (direction === 'right') {
                let newIndex = this.activeImage.index + 1;
                if (newIndex === this.images.length) {
                    this.activeImage = this.images[0];
                    this.activeImage.index = 0;
                } else {
                    this.activeImage = this.images[newIndex];
                    this.activeImage.index = newIndex;
                }
            }

            if (direction === 'left') {
                let newIndex = this.activeImage.index - 1;
                if (newIndex === -1) {
                    this.activeImage = this.images[this.images.length - 1];
                    this.activeImage.index = this.images.length - 1;
                } else {
                    this.activeImage = this.images[newIndex];
                    this.activeImage.index = newIndex;
                }
            }

        },
        deleteItem(item){
            // TODO ( Remove Image from storage & collection ) & confirm delete 
            let artwork = clonedeep(this.artwork);

            this.isImageEditMode = false;

            this.images.splice(item.index, 1);
            artwork.images = this.formattedImages;
            catalogService.update(artwork);
        },
        async fileAdded(file) {
            this.isLoading = true;

            try {
                let params = {
                    files: [file], 
                    artworkId: this.artwork.id
                }
                
                let response = await images.upload(params);
                response.images.push(response.image);
                this.images = response.images;
                this.$forceUpdate()
                    
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false;
            }

        },
        reorderImages(e){
            let artwork = clonedeep(this.artwork);
            artwork.images = this.images;
            catalogService.update(artwork);
        }
    },
    computed: {
        dragOptions () {
            return {
                animation: 300,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            };
        },
        hasImages() {
            return this.images && this.images.length;
        },
        formattedImages() {
            let images = this.images.map((image) => {
                return {
                    url: image.url,
                    id: image.id
                }
            })
            return images
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        draggable,
        ImageViewer
    }
};
</script>
<style lang="scss">
.ghost {
    border: 2px dashed #cbd0db;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
    }
}
.dz-preview, .dz-error, .dz-complete, .dz-image-preview {
    display: none !important;
}
</style>
<style lang="scss" scoped>
$image-size: 140px;
$first-image-size: 300px;
$min-image-size: unset;
$max-image-size: unset;
$image-background: #F5F6F8;
$image-radius: 10px;

.image-tools {
    &__rows {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 2px;
    }
    &__row-main {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: wrap;
        padding-top: 10px;
        width: auto;
    }
    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: wrap;
        padding: 10px;
    }
    &__image {
        position: relative;
        width: $image-size;
        height: $image-size;
        min-height: $min-image-size;
        min-width: $min-image-size;
        max-height: $max-image-size;
        max-width: $max-image-size;
        margin: 0.5vw;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
            .image-tools__options {
                opacity: 1;
                pointer-events: unset;
            }
            .image-tools__expand {
                opacity: 1;
                pointer-events: unset;
            }
        }
    }
    .dropzone-container {
        width: 100%;
        max-width: unset;
    }
    #dropzone {
        position: relative;
        width: $image-size;
        height: $image-size;
        min-height: $min-image-size;
        min-width: $min-image-size;
        max-height: $max-image-size;
        max-width: $max-image-size;
        margin: 0.5vw;
        cursor: pointer;
        border-radius: $image-radius;
        border: none;
        background-color: $image-background;
        font-family: unset;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
            content: "";
            position: absolute;
            top: 5%;
            left: 5%;
            width: 90%;
            height: 90%;
            border-radius: $image-radius;
            border: 2px dashed #dce1ea;
        }
    }
    .main-image {
        width: $first-image-size;
        height: $first-image-size;
    }
    &__image-container {
        width: 100%;
        height: 100%;
        background-color: $image-background;
        border-radius: $image-radius;
        background-repeat: none;
        background-position: center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        overflow: hidden;
    }

    &__options {
        position: absolute;
        right: 5px;
        top: 10px;
        opacity: 0;
        pointer-events: none;
    }
    &__expand {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50%;
        top: 50%;
        margin: -25px 0 0 -25px;
        opacity: 0;
        pointer-events: none;
    }
}
</style>
