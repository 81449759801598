import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import firebaseConfig from "../../config/firebase";

const db = firebase.firestore();

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export default {
  create: async (item) => {
    item.createdAt = new Date();
    item.updatedAt = new Date();
    let contactId = "";

    await db
      .collection("alcove")
      .doc("database")
      .collection("contacts")
      .add(item)
      .then((docRef) => {
        db.collection("alcove")
          .doc("database")
          .collection("contacts")
          .doc(docRef.id)
          .update({ id: docRef.id });
        contactId = docRef.id;
      });
    return contactId;
  },
  update: (item) => {
    item.updatedAt = new Date();
    db.collection("alcove")
      .doc("database")
      .collection("contacts")
      .doc(item.id)
      .update(item);
  },
  getContacts: async (tenantId) => {
    let contacts = [];
    let response = await db
      .collection("alcove")
      .doc("database")
      .collection("contacts")
      .where("tenantId", "==", tenantId)
      .get();

    response.forEach((doc) => {
      if (!doc.data().isDeleted) {
        contacts.push(doc.data());
      }
    });
    return contacts;
  },
  get: async (id) => {
    let contact = await db
      .collection("alcove")
      .doc("database")
      .collection("contacts")
      .doc(id)
      .get();
    return contact.data();
  },
};
