<template>
    <div class="edit-rooms">
        <v-card flat class="content-card hide-mobile">
            <v-row>
                <div class="overline mb-4">Viewing Rooms</div>
            </v-row>
            <v-row>
            <v-col>
            </v-col>
                <v-spacer></v-spacer>
                <v-col class="align-right">
                    <v-btn 
                    depressed 
                    @click="$router.push('/room/new')" 
                    color="secondary"
                    >
                    Add New
                    <v-icon class="ml-2" small>
                        add_circle_outline
                    </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-row class="pa-5">
                    <v-row v-if="isLoading" class="d-flex align-center justify-center">
                        <v-progress-circular width="3" indeterminate size="100" color="secondary"></v-progress-circular>
                    </v-row>
                    <template v-else>
                        <template v-if="!previewMode">
                            <div class="edit-rooms__card d-flex align-end" v-for="(item, index) in rooms" :key="index">
                                <v-col class="align-center">
                                    <v-col v-if="item.siteLogo.url" class="d-flex justify-center mt-4">
                                        <v-img max-height="60%" max-width="60%" :src="item.siteLogo.url"></v-img>
                                    </v-col>
                                    <v-col v-else>{{item.name}}</v-col>
                                    <v-row class="mt-4">
                                        <v-col class="d-flex justify-start">
                                            <a :href="`${urls.room}/${item.urlPath}`" target="_blank" rel="noopener noreferrer">
                                                <v-btn text fab x-small color="#f1f3f6" depressed>
                                                    <v-icon color="secondary">open_in_new</v-icon>
                                                </v-btn> 
                                            </a> 
                                        </v-col>
                                        <v-col class="align-right">
                                            <v-btn @click="$router.push({name: routes.room, params: {id: item.id}})" fab x-small color="#f1f3f6" depressed>
                                                <v-icon color="secondary">edit</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </div>
                        </template>
                        <template v-else>
                            <v-row v-if="rooms && rooms.length">
                            <div v-if="rooms[0]" class="edit-rooms__card d-flex align-end">
                                <v-col class="align-center">
                                    <v-col v-if="rooms[0].siteLogo.url" class="d-flex justify-center mt-4">
                                        <v-img max-height="60%" max-width="60%" :src="rooms[0].siteLogo.url"></v-img>
                                    </v-col>
                                    <v-col v-else>{{rooms[0].name}}</v-col>
                                    <v-row class="mt-4">
                                        <v-col class="d-flex justify-start">
                                            <a :href="`${urls.room}/${rooms[0].urlPath}`" target="_blank" rel="noopener noreferrer">
                                                <v-btn text fab x-small color="#f1f3f6" depressed>
                                                    <v-icon color="secondary">open_in_new</v-icon>
                                                </v-btn> 
                                            </a> 
                                        </v-col>
                                        <v-col class="align-right">
                                            <v-btn @click="$router.push({name: routes.room, params: {id: rooms[0].id}})" fab x-small color="#f1f3f6" depressed>
                                                <v-icon color="secondary">edit</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </div>
                            <div v-if="rooms[1]" class="edit-rooms__card d-flex align-end">
                                <v-col class="align-center">
                                    <v-col v-if="rooms[1].siteLogo.url" class="d-flex justify-center mt-4">
                                        <v-img max-height="60%" max-width="60%" :src="rooms[1].siteLogo.url"></v-img>
                                    </v-col>
                                    <v-col v-else>{{rooms[1].name}}</v-col>
                                    <v-row class="mt-4">
                                        <v-col class="d-flex justify-start">
                                            <a :href="`${urls.room}/${rooms[1].urlPath}`" target="_blank" rel="noopener noreferrer">
                                                <v-btn text fab x-small color="#f1f3f6" depressed>
                                                    <v-icon color="secondary">open_in_new</v-icon>
                                                </v-btn> 
                                            </a> 
                                        </v-col>
                                        <v-col class="align-right">
                                            <v-btn @click="$router.push({name: routes.room, params: {id: rooms[1].id}})" fab x-small color="#f1f3f6" depressed>
                                                <v-icon color="secondary">edit</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </div>
                            <div @click="$router.push({name: routes.rooms})" class="edit-rooms__card d-flex align-center">
                                <v-col class="align-center">
                                    <v-col>View All</v-col>
                                </v-col>
                            </div>
                            </v-row>
                        </template>
                    </template>
                </v-row>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import roomService from '@/services/roomService';
import { urls, routes } from '@/utils/constants';
import { mapGetters } from 'vuex';

    export default {
        props: ['previewMode'],
        data() {
            return {
                isLoading: false,
                rooms:[],
                urls, 
                routes
            }
        },
        async created () {
            try {
                this.isLoading = true;
                this.rooms = await roomService.query(this.tenant.id);

            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }

            const breadcrumbs = {
                items: [
                    {
                        text: 'Marketing',
                        disabled: false,
                        exact: true
                    }
                ]
            };
            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
        },
        computed: {
            ...mapGetters([
            'tenant'
            ])
        }
    }
</script>

<style lang="scss" scoped>
.edit-rooms {
    &__card {
        width: 240px;
        height: 200px;
        border: 1px solid #ddd;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        position: relative;
        margin: 0 20px 20px 0;
    }
}
</style>