export default {
    schema: () => {
        return {
            name: '',
            description: '',
            status: '',
            agent: '',
            artist: '',
            bibliography: '',
            category: '',
            certificateOfAuthenticity: '',
            collection: '',
            condition: '',
            createdAt: '',
            createdBy: '',
            culturalContext: {
                earliestDate: '',
                latestDate: ''
            },
            dateCreated: '',
            dimensions: {
                width: '',
                height: '',
                depth: ''
            },
            edition: [],
            glbURL: '',
            hasCertificateOfAuthenticity: false,
            hasGLB: false,
            hasUSDZ: false,
            isPublished: false,
            isAcquirable: false,
            isBiddable: false,
            isDeleted: false,
            isForSale: false,
            isFramed: false,
            isPriceHidden: false,
            images: [],
            inscription: '',
            links: {
                qrCodeURL: '',
                barcodeURL: ''
            },
            locationId: '',
            location: '',
            medium: '',
            mediumType: '',
            metadata: {},
            notes: '',
            ownerName: '',
            period: '',
            price: '',
            quantity: 1,
            relation: '',
            rights: {
                rightsHolder: ''
            },
            slug: '',
            source: '',
            subject: '',
            tenantId: '',
            technique: '',
            textReference: '',
            updatedAt: '',
            updatedBy: '',
            usdzURL: '',
            version: 1,
            work: '',
            weight: '',
            year: '',            
        }
    }
}