<script>

import { Line, mixins } from 'vue-chartjs';

export default {
    name: 'Line',
    extends: Line,
    mixins: [ mixins.reactiveProp ],
    props: {
        chartData: Object
    },
    data () {
        return {
            options: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        }
                    }]
                }
            }
        };
    },
    mounted () {
        this.renderChart(this.chartData, this.options);
    }
};
</script>
