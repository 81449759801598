<template>
  <div class="dashboard">
    <v-row>
        <v-col>
        <v-card flat class="content-card hide-mobile">
            <v-row>
                <div class="overline mb-4">Artwork</div>
            </v-row>
            <v-row>
              <v-col>
                <v-img height="100px" src="../assets/artwork.png"></v-img>
              </v-col>
                <v-spacer></v-spacer>
                <v-col class="align-right">
                    <v-btn 
                    depressed 
                    @click="$router.push('/artwork/new')" 
                    color="secondary"
                    >
                      Add New
                      <v-icon class="ml-2" small>
                        add_circle_outline
                      </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat class="content-card hide-mobile">
            <v-row>
                <div class="overline mb-4">Collections</div>
            </v-row>
            <v-row>
              <v-col>
                <v-img src="../assets/collection.png"></v-img>
              </v-col>
                <v-spacer></v-spacer>
                <v-col class="align-right">
                    <v-btn 
                    depressed 
                    @click="$router.push({name: routes.collection, params: {id: 'new'}})"
                    color="secondary"
                    >
                      Add New
                      <v-icon class="ml-2" small>
                        add_circle_outline
                      </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <EditRooms :previewMode="true"/>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import lineChart from '@/components/graphs/lineChart';
import isEmpty from 'lodash.isempty';
import { store } from '../store'
import { mapGetters } from 'vuex'
import { catalog } from '../store/modules/catalog';
import { routes } from '@/utils/constants';
import EditRooms from '@/components/room/EditRooms';

  export default {
    components: {
      lineChart,
      EditRooms
    },
    data () {
      return {
        routes,
        datacollection: null,
        currentChart: []
      }
    },
    async created(){
        await store.dispatch('LOAD_CATALOG');

            const toolbarActions = {
                items: []
            };
            this.$store.dispatch('SET_TOOLBAR_ACTIONS', toolbarActions);

            const breadcrumbs = {
                items: [
                    {
                        text: 'Overview',
                        disabled: false,
                        exact: true
                    }
                ]
            };
            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
    },
    mounted () {
      this.fillData()
    },
    computed: {
        ...mapGetters([
        'catalog'
        ]),
        catalogIsEmpty(){
          return isEmpty(this.catalog);
        }
    },
    methods: {
      fillData () {
        this.datacollection = this.getCatalog();
      },
      getCatalog() {
        if (!this.catalogIsEmpty){
          return {
            labels: this.catalog.map(catalog => catalog.title),
            datasets: [
                {
                    backgroundColor: [
                        '#171b3a',
                        '#242a5b',
                        '#4854b6',
                        '#a3a9da',
                        '#000'

                    ],
                    label: 'price',
                    data: this.catalog.map(catalog => catalog.price)
                }
            ]
          }
        }
      }
    }
  }
</script>

<style lang="scss">
.dashboard {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    &__row {
        padding: 1em;
    }
  &__card {
      padding: 2em 4em;
      border-radius: 10px;
  }
}
</style>