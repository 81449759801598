export default {
    schema: () => {
        return {
            name: '',
            description: '',
            createdAt: '',
            createdBy: '',
            id: '',
            updatedAt: '',
            updatedBy: '',
            lineItems: [],
            isPublished: false,
            isDeleted: false,
            startPublishedDate: '',
            endPlublishedDate: '',
            publishedDuration: '',
            tenantId: '',
            version: 1
        }
    }
}