import * as firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, updateDoc ,doc, getDoc,collection  } from 'firebase/firestore/lite';
import firebaseConfig from "../../config/firebase";
import { routes } from '@/utils/constants';
const app = initializeApp(firebaseConfig)
const db = getFirestore(app);


export default {
  create: async (item) => {
    item.createdAt = new Date();
    item.updatedAt = new Date();
    const docRef = await addDoc(collection(db,"alcove","database","catalog"),
      item
    );
      console.log(docRef.id)
    updateDoc(doc(db,"alcove","database","catalog",docRef.id),{
      id:docRef.id
    })

    return docRef.id;
  },  
  update: (item) => {
    item.updatedAt = new Date();
    updateDoc(doc(db,"alcove","database","catalog",item.id),
      item
    )
  },
  getRoomCatalog: (params) => {
    let catalog = [];
    params.lineItems.forEach(async (id) => {
      let itemRef = doc (db,"alcove","database","catalog",id)
      const itemSnap = await getDoc(itemRef)
      if (itemSnap.data()) {
        catalog.push(itemSnap.data());
      }
    });
    return catalog;
  },
  getCollectionCatalog: (params) => {
    let catalog = [];
    params.lineItems.forEach(async (id) => {
      let item = await db
        .collection("alcove")
        .doc("database")
        .collection("catalog")
        .doc(id)
        .get();
      if (item.data()) {
        catalog.push(item.data());
      }
    });
    return catalog;
  },
};
