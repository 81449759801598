import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import Vue from "vue";
import router from "../../router";
import firebaseConfig from "../../../config/firebase";

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

Vue.use(firebase);
const db = firebase.firestore();

const state = {
  collections: [],
  collection: {},
  isLoading: false,
};
const getters = {
  collections: (state) => state.collections,
  collection: (state) => state.collection,
  isLoading: (state) => state.isLoading,
};
const mutations = {
  loadCollections(state, payload) {
    state.collections = payload;
  },
  loadCollection(state, payload) {
    state.collection = payload;
  },
  loading(state) {
    state.isLoading = true;
  },
};
const actions = {
  LOAD_COLLECTIONS(context) {
    context.commit("loading");
    db.collection("alcove")
      .doc("database")
      .collection("collections")
      .onSnapshot(function (querySnapshot) {
        const payload = [];
        querySnapshot.forEach(function (doc) {
          payload.push(doc.data());
        });
        context.commit("loadCollections", payload);
      });
  },
  CREATE_COLLECTION(context, payload) {
    context;
    const createdAt = new Date();

    db.collection("alcove")
      .doc("database")
      .collection("collections")
      .add(payload.collection)
      .then((docRef) => {
        return {
          updateRecord: db
            .collection("alcove")
            .doc("database")
            .collection("collections")
            .doc(docRef.id)
            .update({
              id: docRef.id,
              createdAt: createdAt,
            }),
          changeRoute: router.push("/collections"),
        };
      });
  },
  LOAD_COLLECTION(context, payload) {
    context.commit("loading");
    db.collection("collections")
      .doc(payload)
      .onSnapshot(function (doc) {
        context.commit("loadClaimProfile", doc.data());
      });
  },
};

export const collections = {
  state,
  mutations,
  actions,
  getters,
};
