<template>
    <div class="rooms">
        <v-row>
            <v-col>
                <EditRooms 
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import EditRooms from '@/components/room/EditRooms';

    export default {
        components: {
            EditRooms
        }
    }
</script>