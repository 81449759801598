import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
      },
    theme: {
        themes: {
          light: {
            primary: '#09314E',
            secondary: '#379D9E',
            accent: '#E9EEFF',
            error: '#b71c1c',
            background: "#F5F6F8"
          },
        },
      },
});
