import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebaseConfig from "../../config/firebase";

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

export default {
  query: async (tenantId) => {
    try {
      let collections = [];

      let response = await db
        .collection("alcove")
        .doc("database")
        .collection("collection")
        .where("tenantId", "==", tenantId)
        .get();

      if (
        response &&
        response.docs &&
        response.docs.length &&
        response.docs[0] &&
        !response.empty
      ) {
        response.forEach((doc) => {
          collections.push(doc.data());
        });
        return collections;
      } else {
        return console.log("no records");
      }
    } catch (error) {
      return console.log("error fetching data", error);
    }
  },
  get: async (id) => {
    let item = await db
      .collection("alcove")
      .doc("database")
      .collection("collection")
      .doc(id)
      .get();
    return item.data();
  },
  update: async (item) => {
    return await db
      .collection("alcove")
      .doc("database")
      .collection("collection")
      .doc(item.id)
      .update(item);
  },
  create: async (item) => {
    item.createdAt = new Date();
    item.updatedAt = new Date();

    db.collection("alcove")
      .doc("database")
      .collection("collection")
      .add(item)
      .then((docRef) => {
        db.collection("alcove")
          .doc("database")
          .collection("collection")
          .doc(docRef.id)
          .update({ id: docRef.id });
      });
  },
  uploadLogo: async (payload) => {
    // TODO remove old logo
    let image = payload.files[0];
    let newImage = {};

    newImage.createdAt = new Date();
    newImage.name = image.name;
    newImage.size = image.size;
    newImage.type = image.type;
    newImage.tenantId = payload.tenantId;
    newImage.collectionId = payload.collectionId;

    try {
      const docRef = await db
        .collection("media")
        .doc("database")
        .collection("images")
        .add(newImage);

      const fileRef = `/media/collections/${payload.collectionId}/${docRef.id}/${newImage.name}`;

      await firebase.storage().ref(fileRef).put(image);

      let url = await firebase.storage().ref(fileRef).getDownloadURL();

      let formattedImage = {
        id: docRef.id,
        url: url,
      };

      await db
        .collection("alcove")
        .doc("database")
        .collection("collection")
        .doc(payload.collectionId)
        .update({
          siteLogo: formattedImage,
        });

      return url;
    } catch (error) {
      console.log(error);
    }
  },
};
