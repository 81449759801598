<template>
    <div class="platform">
        <v-dialog v-model="editPlatform" width="800px">
            <v-card flat class="pa-8">
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="activeTenant.name"
                        label="Tenant Name"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="activeTenant.email"
                        label="Primary Email"
                        >

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex justify-end">
                        <v-btn @click="save" depressed color="secondary">save</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-card flat class="content-card hide-mobile" min-width="800px">
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="align-right">
                    <v-btn class="ml-2" depressed @click="editSelected()" color="secondary">Add New
                        <v-icon class="ml-2" small>
                            add_circle_outline
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
            :headers="headers"
            :items="tenants"
            >
            <!-- <template v-slot:item.edit="{ item }">
                <v-row>
                    <v-btn @click="editSelected(item)" fab x-small color="#f1f3f6" depressed>
                        <v-icon color="secondary">edit</v-icon>
                    </v-btn>
                </v-row>
            </template> -->
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import platformService from '@/services/platformService';
import Users from '@/components/users/Users';

    export default {
        data() {
            return {
                activeTenant: {
                    name: '',
                    email: ''
                },
                editPlatform: false,
                tenants: [],
                headers: [
                { text: 'Name', value: 'name' },
                { text: 'Actions', value: 'edit' }
                ]
            }
        },
        async created () {
            const breadcrumbs = {
                items: [
                    {
                        text: 'Platform',
                        disabled: false,
                        exact: true
                    }
                ]
            };
            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);
            this.tenants = await platformService.query();
        },
        methods: {
            editSelected(item) {
                this.editPlatform = true;
                // this.activeTenant = item;
            },
            async save() {
                console.log(this.activeTenant)
                let tenantId = await platformService.create(this.activeTenant);
                const payload = {};

                payload.permissions = {
                    canAdd: true,
                    canEdit: true,
                    canDelete: true,
                    canView: true,
                    isAdmin: true
                }
                

                payload.email = this.activeTenant.email;
                payload.onboard = tenantId;
                this.$store.dispatch('CREATE_USER', payload);
            }
        },
        components: {
            Users
        }
    }
</script>

<style lang="scss" scoped>

</style>