

const state = {
    toolbarActions: {
        items: [{
            text: '...',
            disabled: true
        }]
    }
}
const getters = {
    toolbarActions: state => state.toolbarActions
}
const mutations = {
    updateToolbarActions (state, payload) {
        state.toolbarActions.items = [];
        state.toolbarActions.items = payload.items;
        state.toolbarActions.viewTitle = payload.viewTitle;
    }
}
const actions = {
    SET_TOOLBAR_ACTIONS(context, payload) {
        context.commit('updateToolbarActions', payload);
    }
}

export const toolbarActions = {
    state,
    mutations,
    actions,
    getters
}

