import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
const axios = require("axios").default;
import Vue from "vue";
import { deployment } from "@/deployment.js";
import { Store } from "vuex";
import firebaseConfig from "../../../config/firebase";
import { getAuth, signOut } from "firebase/auth";

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

Vue.use(firebase);
// const db = firebase.firestore();

const state = {
  tenant: {},
  user: {},
  roles: {},
};
const getters = {
  tenant: (state) => state.tenant,
  user: (state) => state.user,
  roles: (state) => state.roles,
};
const mutations = {
  loadUser(state, payload) {
    state.user = payload;
  },
  loadTenant(state, payload) {
    state.tenant = payload;
  },
  fetchPermissions(state, payload) {
    state.roles = payload;
  },
};
const actions = {
  async FETCH_USER(context, token) {
    if (!token) {
      context.dispatch("redirectToLogin");
    }
    await firebase
      .auth()
      .signInWithCustomToken(token)
      .catch((error) => {
        console.log(error);
        if (error.code == "auth/invalid-custom-token") {
          context.dispatch("redirectToLogin");
        }
      });

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const config = {
          headers: {
            authorization: idToken,
          },
        };
        axios
          .get(
            `https://us-central1-roxyart-io.cloudfunctions.net/auth-api/user`,
            config
          )
          .then(async (response) => {
            await context.commit("loadUser", response.data);
            if (state.user.roles) {
              let permissions = state.user.roles.find(
                (role) => role.domain === "alcove"
              );
              context.commit("fetchPermissions", permissions.permissions);
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  FETCH_TENANT(context, token) {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const config = {
          headers: {
            authorization: idToken,
          },
        };
        axios
          .get(
            `https://us-central1-roxyart-io.cloudfunctions.net/auth-api/tenant/${state.user.tenants[0]}`,
            config
          )
          .then(async (response) => {
            await context.commit("loadTenant", response.data);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  redirectToLogin() {
    location.replace(`${deployment.loginUrl}`);
  },
  signOut(context) {
    const auth = getAuth()
    const user = auth.currentUser;
    getAuth().verifyIdToken(user.idToken,true)
    //signOut(userAuth)
      .then((payload) => {
        console.log(payload)
        signOut(auth).then(()=>{});
        //context.dispatch("redirectToLogin");
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

export const auth = {
  state,
  mutations,
  actions,
  getters,
};
