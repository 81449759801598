import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import firebaseConfig from "../../config/firebase";

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

export default {
  query: async () => {
    try {
      let tenants = [];

      let response = await db
        .collection("users")
        .doc("tenant")
        .collection("data")
        .get();

      if (
        response &&
        response.docs &&
        response.docs.length &&
        response.docs[0] &&
        !response.empty
      ) {
        response.forEach((doc) => {
          tenants.push(doc.data());
        });
        return tenants;
      } else {
        return console.log("no records");
      }
    } catch (error) {
      return console.log("error fetching data", error);
    }
  },
  create: async (item) => {
    item.createdAt = new Date();
    item.updatedAt = new Date();

    let tenantId = "";

    await db
      .collection("users")
      .doc("tenant")
      .collection("data")
      .add(item)
      .then(async (docRef) => {
        await db
          .collection("users")
          .doc("tenant")
          .collection("data")
          .doc(docRef.id)
          .update({ id: docRef.id });
        tenantId = docRef.id;
      });

    return tenantId;
  },
};
