<template>
    <div class="image-tools">
        <div class="image-tools__rows">
            <div class="image-tools__row">
                    <vue-dropzone 
                    ref="myVueDropzone" 
                    id="dropzone"
                    @vdropzone-thumbnail="fileAdded"
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    :includeStyling="false"
                    >
                    <div class="dropzone-container">
                        <div v-if="thumbnail" class="image-tools__image-container no-touch-events">
                            <v-img v-if="!isUploading" width="200px" :src="thumbnail"></v-img>
                            <v-row v-else>
                                <v-progress-circular width="3" indeterminate size="50" color="secondary"></v-progress-circular>
                            </v-row>
                        </div>
                        <div v-else class="image-tools__image-container no-touch-events">
                            <template v-if="!isLoading">
                                Add Logo
                                <v-icon class="ml-1" small color="secondary">add</v-icon>
                            </template>
                            <template v-else>
                                <v-progress-circular width="3" indeterminate size="50" color="secondary"></v-progress-circular>
                            </template>
                        </div>
                    </div>
                </vue-dropzone>
            </div>
        </div>
    </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const MAX_FILE_SIZE = 2;

export default {
    props: ['thumbnail', 'isUploading'],
    data() {
        return {
            isLoading: false,
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { "My-Awesome-Header": "header value" },
                createImageThumbnails: "false",
                acceptedFiles: "image/*",
                uploadMultiple: false
            }
        }
    },
    methods: {
        async fileAdded(file) {
            this.isLoading = true;
            console.log(file);
            try {
                this.$emit('upload-file', file);
                    
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false;
            }

        }
    },
    components: {
        vueDropzone: vue2Dropzone
    }
};
</script>
<style lang="scss">
.ghost {
    border: 2px dashed #cbd0db;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
    }
}
.dz-preview, .dz-error, .dz-complete, .dz-image-preview {
    display: none !important;
}
</style>
<style lang="scss" scoped>
$image-size: 180px;
$image-width: 100%;
$first-image-size: 300px;
$min-image-size: unset;
$max-image-size: unset;
$image-background: #F5F6F8;
$image-radius: 10px;

.image-tools {
    &__rows {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    &__row-main {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: wrap;
        width: 100%;
    }
    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: wrap;
        width: 100%;
    }
    &__image {
        position: relative;
        width: $image-width;
        height: $image-size;
        min-height: $min-image-size;
        min-width: $min-image-size;
        max-height: $max-image-size;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
            .image-tools__options {
                opacity: 1;
                pointer-events: unset;
            }
            .image-tools__expand {
                opacity: 1;
                pointer-events: unset;
            }
        }
    }
    .dropzone-container {
        width: 100%;
        max-width: unset;
    }
    #dropzone {
        position: relative;
        width: $image-width;
        height: $image-size;
        min-height: $min-image-size;
        min-width: $min-image-size;
        max-height: $max-image-size;
        cursor: pointer;
        border-radius: $image-radius;
        border: none;
        background-color: $image-background;
        font-family: unset;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            right: 10px;
            bottom: 10px;
            left: 10px;

            border-radius: $image-radius;
            border: 2px dashed #dce1ea;
        }
    }
    .main-image {
        width: $first-image-size;
        height: $first-image-size;
    }
    &__image-container {
        width: 100%;
        height: 100%;
        background-color: $image-background;
        border-radius: $image-radius;
        background-repeat: none;
        background-position: center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        overflow: hidden;
    }

    &__options {
        position: absolute;
        right: 5px;
        top: 10px;
        opacity: 0;
        pointer-events: none;
    }
    &__expand {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50%;
        top: 50%;
        margin: -25px 0 0 -25px;
        opacity: 0;
        pointer-events: none;
    }
}
</style>
