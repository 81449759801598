var firebaseConfig = {
  apiKey: "AIzaSyCsgXgK3LRke5oLCMqIFM_HVuWHBxbrnTs",
  authDomain: "roxyart-io.firebaseapp.com",
  databaseURL: "https://roxyart-io.firebaseio.com",
  projectId: "roxyart-io",
  storageBucket: "roxyart-io.appspot.com",
  messagingSenderId: "903224539936",
  appId: "1:903224539936:web:6bde71c39c276cd768f545",
  measurementId: "G-N9WQ6K1NJ4",
};

export default firebaseConfig;
