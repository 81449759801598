export default {
    schema: () => {
        return {
            name: '',
            emails: '',
            phoneNumbers: '',
            addresses: '',
            createdAt: '',
            createdBy: '',
            updatedAt: '',
            updatedBy: '',
            version: 1,
            tenantId: '',
            id: ''
        }
    }
}