<template>
    <div class="contact">
        <toolbar>
            <v-row class="d-flex align-center justify-end">
                <v-spacer></v-spacer>
                <v-col>
                    
                </v-col>
                    <v-btn
                        v-if="contact.id !== 'new'"
                        depressed
                        color="secondary" 
                        @click="save"
                        >
                        Save
                    </v-btn>
                    <v-btn
                        v-else
                        depressed
                        color="secondary" 
                        @click="create"
                        >
                        Create
                    </v-btn>
            </v-row>
        </toolbar>
        <v-card class="contact__card" flat>
            <v-row>
                <div class="overline mb-4">General</div>
            </v-row>
            <v-row>
                <v-col cols="6" md="6">
                    <v-text-field
                        v-model="contact.name"
                        label="Name"
                        required
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                    <v-text-field
                        v-model="contact.email"
                        label="Email"
                        required
                        color="secondary"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="6">
                    <v-text-field
                        v-model="contact.phoneNumber"
                        label="Phone Number"
                        required
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                    <v-text-field
                    v-if="contact"
                        v-model="contact.address"
                        label="Address"
                        required
                        color="secondary"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Toolbar from '@/components/globals/Toolbar';

    export default {
        components: {
            Toolbar
        },
        props: ['contact'],
        methods: {
            save () {
                this.$emit('save', this.contact);
            },
            create () {
                this.$emit('create', this.contact);
            }
        }
    }
</script>
<style>
.v-input--selection-controls {
    margin: 0;
}
</style>
<style lang="scss" scoped>
    .contact {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 100px;


        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__card {
            margin: 1em auto;
            width: 100%;
            max-width: 900px;
            padding: 2em;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 3px 0px;
        }
        &__status {
            max-width: 250px;
        }

    }
</style>