'use strict';

export const urls = {
    room: 'https://viewing.web.app',
    artwork: 'https://viewing.web.app/artwork'
};

export const routes = {
    artwork: 'artwork',
    catalog: 'catalog',
    contact: 'contact',
    contacts: 'contacts',
    room: 'room',
    rooms: 'rooms',
    marketing: 'marketing',
    platform: 'platform',
    collection: 'collection',
    collections: 'collections',
    dashboard: 'dashboard',
    editArtwork: 'edit-artwork',
    settings: 'settings',
    integrations: 'integrations',
    profile: 'profile'
};

