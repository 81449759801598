<template>
    <div class="artwork">
        <EditArtwork
        v-if="editMode"
        :artwork="artwork"
        v-on:save="save"
        :key="artwork.id"
        />
        <v-snackbar
        
        v-model="showMessage"
        :top="true"
        :timeout="1000"
        >
        saved 🎉

        </v-snackbar>
    </div>
</template>

<script>
import { store } from '../store';
import { routes } from '@/utils/constants';
import { mapGetters } from 'vuex';
import EditArtwork from '@/components/artwork/EditArtwork';
import catalogService from '@/services/catalog';
import catalogSchema from '@/utils/catalog';

export default {
    data() {
        return {
            editMode: false,
            id: '',
            artwork: catalogSchema.schema(),
            showMessage: false
        }
    },
    created() {
        this.loadArtwork();
    },
    computed: {
        displayTitle(){
            return this.artwork.title;
        },
        ...mapGetters([
            'collections',
            'catalog'
        ])
    },
    components: {
        EditArtwork
    },
    methods: {
        async save(artwork) {
           
            try {
                if(this.id == 'new'){
                    console.log("CREATING")
                    const itemId = await catalogService.create(artwork);
                    this.$router.push('/artwork/' + itemId)
                    location.reload();
                } else {
                    await catalogService.update(artwork);
                    this.showMessage = true;
                }

            } catch (error) {
                console.log(error)
            }
        },
        formatName(name) {
            const MAX_LENGTH = 20;
            if (name.length > MAX_LENGTH) {
                return `${name.slice(0, MAX_LENGTH)}...`;
            } else {
                return name;
            }
        },
        async loadArtwork() {
            let breadcrumbs = {};
            this.editMode = this.$route.meta.editMode;

            try {
                if (this.editMode){
                    this.id = this.$route.params.id;

                    this.artwork = await store.dispatch('LOAD_ARTWORK', this.id);
                    breadcrumbs = {
                            items: [
                            {
                                text: 'Catalog',
                                disabled: false,
                                exact: true,
                                to: {
                                    name: routes.catalog
                                }
                            },
                            {
                                text: this.artwork.name ? this.formatName(this.artwork.name) : 'Draft',
                                disabled: true
                            }
                        ]
                    }
                } else {
                    breadcrumbs = {
                        items: [
                            {
                                text: 'Catalog',
                                disabled: false,
                                exact: true,
                                to: {
                                    name: routes.catalog
                                }
                            },
                            {
                                text: 'New',
                                disabled: true,
                                exact: true,

                            }
                        ]
                    }
                }
            } catch (error) {
                console.log(error)
            }

            this.$store.dispatch('SET_BREADCRUMBS', breadcrumbs);

        }
    }
}
</script>

<style lang="scss" scoped>
    .artwork {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 100px;


        &__toolbar {
            width: 100%;
            max-width: 900px;
            margin: 4em auto 0 auto;
            background-color: transparent !important;
        }
        &__card {
            margin: 1em auto;
            width: 100%;
            max-width: 900px;
            padding: 2em;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 3px 0px;
        }

    }
</style>