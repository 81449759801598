<template>
    <div class="toolbar">
        <div class="toolbar__tools">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
.toolbar {
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: center;
  margin: 0 auto;
  color: #fff;
  height: auto;
  top: 0;
  position: sticky;
  z-index: 1;
  background: rgba($color: #e8eaee, $alpha: .8);
  
  backdrop-filter: blur(15px);

  &__tools {
      width: 96%;
      height: 60px;
      margin: 0 auto;
      padding: 10px 0;
      position: relative;
  }
}

</style>