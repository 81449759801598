import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/views/Dashboard'
import Catalog from '@/views/Catalog'
import Collection from '@/views/Collection'
import Collections from '@/views/Collections'
import Contacts from '@/views/Contacts'
import Contact from '@/views/Contact'
import Artwork from '@/views/Artwork'
import Settings from '@/views/Settings'
import Profile from '@/views/Profile'
import Marketing from '@/views/Marketing'
import Platform from '@/views/Platform'
import Room from '@/views/Room'
import Rooms from '@/views/Rooms'

import { routes } from '@/utils/constants';

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/catalog',
      name: routes.catalog,
      component: Catalog
    },
    {
      path: '/artwork/:id',
      name: routes.artwork,
      component: Artwork,
      meta: {
        editMode: true
      }
    },
    {
      path: '/edit-artwork/:id',
      name: routes.editArtwork,
      component: Artwork,
      meta: {
        editMode: true
      }
    },
    {
      path: '/collection/:id',
      name: routes.collection,
      component: Collection,
      meta: {
        editMode: true
      }
    },
    {
      path: '/collections',
      name: routes.collections,
      component: Collections
    },
    {
      path: '/contacts',
      name: routes.contacts,
      component: Contacts
    },
    {
      path: '/contact/:id',
      name: routes.contact,
      component: Contact,
      meta: {
        editMode: true
      }
    },
    {
      path: '/profile/:id',
      name: routes.profile,
      component: Profile,
      meta: {
        editMode: true
      }
    },
    {
      path: '/rooms',
      name: routes.rooms,
      component: Rooms
    },
    {
      path: '/room/:id',
      name: routes.room,
      component: Room
    },
    {
      path: '/',
      name: routes.Dashboard,
      component: Dashboard
    },
    {
      path: '/settings',
      name: routes.settings,
      component: Settings
    },
    {
      path: '/marketing',
      name: routes.marketing,
      component: Marketing
    },
    {
      path: '/platform',
      name: routes.platform,
      component: Platform
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }




})

// router.beforeRouteUpdate((to, from, next) => {
//   const id = to.params.id
//   this.AJAXRequest(id)
//   next()
// })


export default router
