var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('v-card',{staticClass:"content-card",attrs:{"flat":""}},[_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"secondary"},on:{"click":_vm.openDialog}},[_vm._v(" add user ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.formattedUsers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.$router.push('/edit-artwork/' + item.id)}}},[_c('v-btn',{attrs:{"fab":"","icon":"","color":"#f6f6f6","depressed":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("edit")])],1)],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pill"},[_c('div',{staticClass:"pill__content"},[(item.roles === 'Owner')?_c('v-icon',{staticClass:"mr-2",attrs:{"dark":"","small":""}},[_vm._v(" star ")]):_vm._e(),_vm._v(" "+_vm._s(item.roles)+" ")],1)])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.$router.push('/profile/' + item.id)}}},[_c('v-btn',{attrs:{"fab":"","icon":"","color":"#f6f6f6","depressed":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("open_in_new")])],1)],1)]}},{key:"item.photoURL",fn:function(ref){
var item = ref.item;
return [(item.photoURL)?_c('v-img',{attrs:{"max-width":"24px","src":item.thumbnail}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',{staticClass:"status"},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.addUserDialog),callback:function ($$v) {_vm.addUserDialog=$$v},expression:"addUserDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add User")])]),_c('v-col',[_c('v-row',{staticClass:"mx-auto justify-center"},[_c('v-col',{staticClass:"col-11"},[_c('v-text-field',{attrs:{"color":"secondary","label":"Email","required":""},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('v-select',{attrs:{"color":"primary","chips":"","items":_vm.permissions,"label":"Permissions","required":""},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.addUserDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }