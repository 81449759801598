<template>
    <v-row class="breadcrumbs">
        <div class="breadcrumbs-row">
            <v-col class="col-6 d-flex align-center justify-start">
                <v-breadcrumbs :items="breadcrumbs.items" class="breadcrumbs-array"></v-breadcrumbs>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center justify-end">
                <v-btn color="#fff" fab small depressed class="mr-4">
                    <v-icon color="secondary">
                        notifications
                    </v-icon>
                </v-btn>
                <v-menu
                left
                depressed
                origin="center center"
                transition="scale-transition"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-if="user.email" 
                    fab 
                    small 
                    depressed 
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    >
                    {{user.email[0]}}
                    </v-btn>
                </template>

                    <v-list
                        min-width="200px"
                    >
                        <v-list-item-group
                            color="secondary"
                        >
                            <v-list-item @click="signOut">
                                <v-list-item-icon>
                                    <v-icon>login</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Sign Out</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>person</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Account Details</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-col>
        </div>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { store } from '@/store'

export default {
    data() {
        return {
        item: {
            icon: 'mdi-wifi',
            text: 'Wifi',
        },
        count: 4
        }
    },
    methods: {
        action (payload) {
            this.$router.push(payload.to)
        },
        signOut() {
            this.$store.dispatch('signOut');
        }
    },
    computed: {
        ...mapGetters([
            'user',
            'breadcrumbs',
            'toolbarActions'
        ])
    }
}
</script>

<style lang="scss" scoped>
$break-small: 600px;

.breadcrumbs {
     width: calc(100% - 250px);
     margin-left: 250px;
     border-bottom: 1px solid #ccc;
     position: fixed;

    @media screen and (max-width: $break-small) {
        width: 100%;
        position: relative;
        margin: unset;
    }
}

.breadcrumbs-row {
    width: 98%;
    margin: 0 auto;
    height: 73px;
    display: flex;
}

</style>
<style lang="scss">
.v-breadcrumbs {
    padding: 0 0 0 2em !important;
}
.v-breadcrumbs li {
    font-size: 16px !important;
    font-weight: 500;
}
.user-icon {
    width: 40px !important;
    height: 40px !important;
}
</style>