var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalog"},[(_vm.roles.canView)?_c('v-card',{staticClass:"content-card hide-mobile",attrs:{"flat":"","min-width":"800px"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"align-right"},[_c('v-btn',{attrs:{"depressed":"","color":"#eee"},on:{"click":_vm.exportAll}},[_vm._v("Export "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" save_alt ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push('/artwork/new')}}},[_vm._v("Add New "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" add_circle_outline ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.catalog,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(" "+_vm._s(_vm.formatName(item.name))+" ")]):_c('span',[_vm._v(" Draft ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.price)?_c('span',[_vm._v(" $"+_vm._s(item.price)+" ")]):_c('span',[_vm._v(" $0 ")])]}},{key:"item.isPublished",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mr-6",attrs:{"color":"secondary","flat":"","inset":"","hide-details":"true"},on:{"change":function($event){return _vm.publishArtwork(item)}},model:{value:(item.isPublished),callback:function ($$v) {_vm.$set(item, "isPublished", $$v)},expression:"item.isPublished"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"#f1f3f6","depressed":""},on:{"click":function($event){return _vm.$router.push('/artwork/' + item.id)}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"fab":"","x-small":"","color":"#f1f3f6","depressed":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"color":"#777"}},[_vm._v("delete_outline")])],1)],1)]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","raised":"","rounded":""},on:{"click":function($event){return _vm.$router.push('/artwork/' + item.id)}}},[(item.images[0])?_c('v-img',{attrs:{"max-width":"35px","src":item.images[0].url}}):_vm._e()],1)]}}],null,true)})],1):_vm._e(),_vm._l((_vm.catalog),function(item,index){return _c('v-card',{key:index,staticClass:"content-card ma-1 hide-desktop",attrs:{"flat":""}},[_c('div',[_c('v-row',[_c('v-col',{staticClass:"col-6"},[(item.images[0])?_c('v-img',{attrs:{"max-width":"100%","src":item.images[0].url}}):_vm._e()],1),_c('v-col',{staticClass:"col-6"},[_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_vm._v(" "+_vm._s(item.creator)+" ")])],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"align-right"},[_c('v-btn',{attrs:{"fab":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push('/edit-artwork/' + item.id)}}},[_c('v-icon',[_vm._v(" edit ")])],1)],1)],1)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }