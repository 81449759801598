
// import Vue from 'vue';
// import router from '../../router'

const state = {
    breadcrumbs: {
        items: [{
            text: '...',
            disabled: true
        }]
    }
}
const getters = {
    breadcrumbs: state => state.breadcrumbs
}
const mutations = {
    updateBreadcrumbs (state, payload) {
        state.breadcrumbs.items = [];
        state.breadcrumbs.items = payload.items;
        state.breadcrumbs.viewTitle = payload.viewTitle;
    }
}
const actions = {
    SET_BREADCRUMBS (context, payload) {
        context.commit('updateBreadcrumbs', payload);
    }
}

export const breadcrumbs = {
    state,
    mutations,
    actions,
    getters
}

