const env = {
    LOCAL: {
        alcoveUrl: 'http://localhost:8100',
        loginUrl: 'http://localhost:8200'
    },
    DEV: {
        alcoveUrl: 'https://alcove.roxyart.io',
        loginUrl: 'https://roxy-login.web.app/'
    },
    TEST: {
        alcoveUrl: 'https://alcove.roxyart.io',
        loginUrl: 'https://roxy-login.web.app/'
    },
    PROD: {
        alcoveUrl: 'https://alcove.roxyart.io',
        loginUrl: 'https://login.roxyart.io/'
    }
};

// change for production
export const deployment = env.PROD;
//export const deployment = env[process.env.VUE_APP_DEPLOYMENT];
//export const deployment = env.PROD

