<template>
    <div class="users">
        <v-card flat class="content-card">
            <v-row>
                <v-spacer></v-spacer>
                <v-btn depressed color="secondary" @click="openDialog">
                    add user
                </v-btn>
            </v-row>
            <v-data-table
            :headers="headers"
            :items="formattedUsers"
            :search="search"
            >
            
            <template v-slot:item.edit="{ item }">
              <span @click="$router.push('/edit-artwork/' + item.id)">
              <v-btn fab icon color="#f6f6f6" depressed>
                <v-icon color="secondary">edit</v-icon>
              </v-btn>
              </span>
            </template>

        <template v-slot:item.roles="{ item }">
            <div class="pill">
                <div class="pill__content">
                    <v-icon dark small class="mr-2" v-if="item.roles === 'Owner'">
                        star
                    </v-icon>
                    {{item.roles}}
                </div>
            </div>

        </template>

            <template v-slot:item.view="{ item }">
              <span @click="$router.push('/profile/' + item.id)">
              <v-btn fab icon color="#f6f6f6" depressed>
                <v-icon color="secondary">open_in_new</v-icon>
              </v-btn>
              </span>
            </template>

            <template v-slot:item.photoURL="{ item }">
                <v-img v-if="item.photoURL" max-width="24px" :src="item.thumbnail"></v-img>
            </template>

            <template v-slot:item.status="{ item }">
                <span v-if="item.status" class="status">
                    {{item.status}}
                </span>
            </template>

            </v-data-table>
        </v-card>


    <v-dialog v-model="addUserDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add User</span>
        </v-card-title>
            <v-col>
                <v-row class="mx-auto justify-center">
                    <v-col class="col-11">
                        <v-text-field v-model="newEmail" color="secondary" label="Email" required></v-text-field>
                        <v-select v-model="selectedRoles" color="primary" chips :items="permissions" label="Permissions" required></v-select>
                    </v-col>
                </v-row>
            </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="addUserDialog = false">Close</v-btn>
          <v-btn color="secondary" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                newEmail: '',
                addUserDialog: false,
                selectedRoles: [],
                headers: [
                { text: 'User', value: 'email' },
                { text: 'Permissions', value: 'roles' },
                { text: 'Status', value: 'status' },
                ],
                permissions: [
                    'Owner',
                    'Editor',
                    'Viewer'
                ],
                mock: [
                    {
                        email: 'bob@gmail.com',
                        role: 'Admin'
                    }
                ]
            }
        },
        created(){
            this.$store.dispatch('LOAD_USERS', this.tenant.id);
        },
        methods: {
            openDialog() {
                this.addUserDialog = true;
            },
            filterPermissions(val){
                if (val) {
                    if (val.isAdmin){
                        return 'Owner'
                    } 
                    if (val.canEdit && !val.isAdmin) {
                        return 'Editor'
                    }
                    if (!val.canEdit && !val.isAdmin) {
                        return 'Viewer'
                    }

                } else {
                    return "Roles Error"
                }

            },
            save() {
                const payload = {};

                if (this.selectedRoles === 'Owner') { 
                    payload.permissions = {
                        canAdd: true,
                        canEdit: true,
                        canDelete: true,
                        canView: true,
                        isAdmin: true
                    }
                }

                if (this.selectedRoles === 'Editor') { 
                    payload.permissions = {
                        canAdd: true,
                        canEdit: true,
                        canDelete: true,
                        canView: true,
                    }
                }

                if (this.selectedRoles === 'Viewer') { 
                    payload.permissions = {
                        canView: true
                    }
                }
                console.log(payload);
                payload.email = this.newEmail;
                payload.onboard = this.tenant.id;
                this.$store.dispatch('CREATE_USER', payload);
            }
        },
  computed: {
      formattedUsers() {
          return this.users.map(user => ({
              ...user,
              status: user.roles[0].status,
              roles: this.filterPermissions(user.roles[0].permissions)
          }))
      },
    ...mapGetters([
      'tenant',
      'users',
      'roles'
    ])
  }
    }
</script>

<style lang="scss" scoped>
.users {
    .pill {
        display: flex;
        &__content {
            background-color: #379D9E;
            color: #fff;
            border-radius: 50px;
            padding: 5px 15px;
            margin-right: 3px;
            display: flex;
        }
    }
}
</style>